import { SitePageRenderInfo } from "../../../common-app/sites/models/sitePageRenderInfo";
import { ApiService } from "../../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../../common-frontend/services/api/backendServerEndpointProvider";
import {
  GetSitePageRenderInfoRouteDef,
  GetSitePageRenderInfoResponse
} from "../../../common-app/sites/actions/pages/getSitePageRenderInfo";
import {
  UpdatePageDataStaticRequestBody,
  UpdatePageDataStaticRouteDef
} from "../../../common-app/pagesStatic/actions/updatePageDataStatic";
import { RequestHeaderHelper } from "../../requestHeaderData/requestHeaderHelper";

export class ServiceApiSitesInfo extends ApiService {

  static async getSitePageRenderInfo(
    pageUrlPart: string,
    sessionId: string,
    deviceId: string
  ): Promise<SitePageRenderInfo | undefined> {

    const routeDef = new GetSitePageRenderInfoRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSitePageRenderInfoResponse>(
      routeDef.route(pageUrlPart, sessionId, deviceId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return undefined;
    }

    return response.pageRenderInfo;
  }

  static async updatePageDataStatic(siteId: string, pageId: string, contents: string): Promise<boolean> {

    const routeDef = new UpdatePageDataStaticRouteDef();
    const requestBody = new UpdatePageDataStaticRequestBody(contents);
    const requestOptions = this.getRequestOptions(routeDef, requestBody);

    return this.DoRequest(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }
}
