import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { TreeNode } from '../../../../common/util/tree/treeNode';
import { TreeNodeUtil } from '../../../../common/util/tree/treeNodeUtil';
import { SitePage } from '../../../../common-app/sites/models/sitePage';
import { ServiceApiSites } from '../../../../services/api/serviceApiSites';

import './dynamicSiteTreeNavigation.css';

interface DynamicSiteTreeNavigationProps {
  siteId: string;
  onNavigationItemClicked: () => void;
}

export function DynamicSiteTreeNavigationComponent({
  siteId,
  onNavigationItemClicked }: DynamicSiteTreeNavigationProps): JSX.Element {

  const [tree, setTree] = useState<TreeNode<SitePage>[]>([]);

  useEffect(() => {

    const getPages = async () => {

      // Retrieve the pages
      const pages = await ServiceApiSites.getSitePages(siteId);

      // Build a tree based on nodes relations
      const tree =
        TreeNodeUtil.buidlTreeFromList(
          pages,
          (node, parentNode) => node.parentPageId === parentNode.id,
          (node) => node.parentPageId === "" || node.parentPageId === null,
          (node) => node.id,
          (node) => node.name,
          () => true
        );

      setTree(tree);
    };

    getPages();

  }, [siteId]);

  const renderPage = (page: TreeNode<SitePage>) => {

    return (
      <div
        key={page.id}>
        <Link to={page.item.fullUrl}
          onClick={onNavigationItemClicked}>
          <div
            className={classNames("item")}
          >
            {page.name}
          </div>
        </Link>

        <div className="item-children">
          {page.children.map((childPage) => {
            return <div key={childPage.id}>
              {renderPage(childPage)}
            </div>
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="dynamic-site-tree-navigation-component">

      {tree.map((page) => {
        return renderPage(page);
      })}
    </div>
  );
}
