import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ImageUploaderModalComponent } from '../../../../imageUploader/imageUploaderModal';
import { GetImagesInfoFunction, GetImagesListFunction, UploadImageFunction } from '../../../../imageUploader/imageBrowser';
import { HorizontalListComponent } from '../../../../horizontalList/horizontalList';
import { Spanner } from '../../../../micro/spanner/spanner';
import { VerticalListComponent } from '../../../../verticalList/verticalList';

import './elementConfigurationSnippetGallerySelection.css';

interface ElementConfigurationSnippetGallerySelectionProps {
  uploadImageFunction: UploadImageFunction,
  getImagesListFunction: GetImagesListFunction,
  getImagesInfoFunction: GetImagesInfoFunction,
  imagesSelected: string[],
  onImagesSelected: (images: string[]) => void
}

export function ElementConfigurationSnippetGallerySelectionComponent({
  uploadImageFunction,
  getImagesListFunction,
  getImagesInfoFunction,
  imagesSelected,
  onImagesSelected,
}: ElementConfigurationSnippetGallerySelectionProps): JSX.Element {

  const [showDialog, setShowDialog] = useState(false);

  return (
    <div className="element-configuration-snippet-gallery-selection-component">

      <VerticalListComponent>

        <HorizontalListComponent>
          <Button
            onClick={() => {
              setShowDialog(true);
            }}
          >
            Escolher imagens
          </Button>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            {imagesSelected.length} imagens seleccionadas
          </div>
          <Spanner />
        </HorizontalListComponent>

        <ImageUploaderModalComponent
          imagesSelected={imagesSelected}
          uploadImageFunction={uploadImageFunction}
          getImagesListFunction={getImagesListFunction}
          getImagesInfoFunction={getImagesInfoFunction}
          showDialog={showDialog}
          onClose={() => {
            setShowDialog(false)
          }}
          allowMultipleSelection={true}
          onConfirm={(images) => {
            onImagesSelected(images);
            setShowDialog(false)
          }}
        />
      </VerticalListComponent>
    </div>
  );
}
