import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';

interface ButtonCopyToClipboardProps {
    size?: 'sm' | 'lg' | undefined;
    valueToCopy: string;
}

export function ButtonCopyToClipboardComponent({
    size = undefined,
    valueToCopy,
}: ButtonCopyToClipboardProps): JSX.Element {

    const [buttonClicked, setButtonClicked] = useState(false);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(valueToCopy);
        setButtonClicked(true);
    }

    useEffect(() => {
        setButtonClicked(false);
    }, [valueToCopy]);

    return (

        <Button
            variant='outline-primary'
            size={size}
            disabled={buttonClicked}
            onClick={handleCopyToClipboard}
        >
            {buttonClicked ? 'Copiado!' : 'Copiar'}
        </Button>

    );
}
