import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import './dynamicPageNotFound.css';

interface DynamicPageNotFoundProps {
  fullPath: string;
  siteDomain: string;
  pagePath: string;
}

export function DynamicPageNotFoundComponent({ fullPath, siteDomain, pagePath }: DynamicPageNotFoundProps): JSX.Element {

  return <Container className="dynamic-page-not-found-component">

    <div className="items-list">
      <div>
        <h1>Página não encontrada</h1>
        <h4>{fullPath}</h4>
      </div>

      <div>
        <div>A página que está a tentar aceder não existe ou não está disponível.</div>
        <div>Por favor, verifique o endereço da página e tente novamente.</div>
      </div>

      <div>

        <Link to={`/sites/${siteDomain}/`}><h5>Página inicial do site</h5></Link>
        <Link to={`/`}><h5>Pixel Vaidoso</h5></Link>
      </div>
    </div>

  </Container>
}