import { TypeCompare } from "../../../common/util/types/typeCompare";

export class Contact {
  id: string = '';
  pageId: string = '';
  enabled: boolean = false;
  displayOrder: number = 0;
  name: string = "";
  mail01: string = "";
  mail02: string = "";
  mail03: string = "";
  phone01: string = "";
  phone02: string = "";
  phone03: string = "";
  addressLine01: string = "";
  addressLine02: string = "";
  addressLine03: string = "";
  addressLine04: string = "";
  latitude: number = 0;
  longitude: number = 0;
  showMap: boolean = false;

  public static isEqual(a: Contact, b: Contact): boolean {
    return (
      a.id === b.id &&
      a.pageId === b.pageId &&
      TypeCompare.AreEqual(a.enabled, b.enabled) &&
      a.displayOrder === b.displayOrder &&
      a.name === b.name &&
      a.mail01 === b.mail01 &&
      a.mail02 === b.mail02 &&
      a.mail03 === b.mail03 &&
      a.phone01 === b.phone01 &&
      a.phone02 === b.phone02 &&
      a.phone03 === b.phone03 &&
      a.addressLine01 === b.addressLine01 &&
      a.addressLine02 === b.addressLine02 &&
      a.addressLine03 === b.addressLine03 &&
      a.addressLine04 === b.addressLine04 &&
      a.latitude === b.latitude &&
      a.longitude === b.longitude &&
      TypeCompare.AreEqual(a.showMap, b.enabled));
  }
}