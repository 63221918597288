import { useState } from "react";
import { ContainerLabeledComponent } from "../../../../../common-frontend/components/containerLabeled/containerLabeled";
import { HorizontalListComponent } from "../../../../../common-frontend/components/horizontalList/horizontalList";
import { AdminUserQueriesSelector } from "../../../../../pages/admin/userQueries/adminUserQueriesSelector/adminUserQueriesSelector";
import { AdminUserQueryDetailComponent } from "../../../../../pages/admin/userQueries/adminUserQueryDetail/adminUserQueryDetail";
import { DynamicPageFeatureComponentProps } from "../dynamicPageFeatureMapping";
import { UserQueryAdminList } from "../../../../../common-app/userQueries/models/userQueryAdminList";

export function PageFeatureUserQueryComponent({
  siteId,
  pageId
}: DynamicPageFeatureComponentProps): JSX.Element {

  // The current item selected in the list
  const [selectedUserQuery, setSelectedUserQuery] = useState<UserQueryAdminList | undefined>(undefined);

  return (
    <div className="page-feature-user-query-component">
      <ContainerLabeledComponent
        label="Mensagens de Utilizador"
        uniqueId={`feature-user-query-${pageId}`}
        defaultViewState="closed"
      >
        <HorizontalListComponent>
          <AdminUserQueriesSelector
            siteId={siteId}
            pageId={pageId}
            onUserQuerySelected={setSelectedUserQuery}
          />

          <AdminUserQueryDetailComponent
            item={selectedUserQuery}
          />
        </HorizontalListComponent>
      </ContainerLabeledComponent>
    </div>
  );
}