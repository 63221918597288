import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";

export class Create1ClickSiteRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");
  }

  public route(): string {
    return "sites/1-click";
  }

  public routeTemplate(): string {
    return "sites/1-click";
  }
}

export class Create1ClickSiteRequestBody extends RequestBody {
  constructor(
    public siteId: string,
    public name: string,
    public creationDeviceId: string,
    public siteOwner: string
  ) {
    super();
  }
}