import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { HorizontalListComponent } from '../horizontalList/horizontalList';
import { LocalStorage } from '../../library/localStorage/localStorage';

import './containerLabeled.css';

interface ContainerLabeledProps {
  uniqueId?: string;
  defaultViewState?: "opened" | "closed";

  label: string;
  children: React.ReactNode;
}

export function ContainerLabeledComponent({
  uniqueId,
  defaultViewState,
  label,
  children }: ContainerLabeledProps): JSX.Element {

  const [isExpanded, setIsExpanded] = useState<boolean>(uniqueId ? false : true);

  const buildLocalStorageKey = (uniqueId: string) => {
    return `expanded-state-${uniqueId}`;
  };

  useEffect(() => {

    if (uniqueId) {

      let expanded = true;
      const expandedFromStorage = LocalStorage.loadItem<boolean>("container-labeled", buildLocalStorageKey(uniqueId));
      if (expandedFromStorage === undefined) {
        // If the expanded state is not in the local storage, then we check if the default view state is set
        expanded = defaultViewState === "closed" ? false : true;
      }
      else {
        if (expandedFromStorage === false) {
          expanded = false;
        }
      }

      setIsExpanded(expanded);
    }
  }, [uniqueId]);

  const onHeaderClick = () => {

    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);

    if (uniqueId) {
      LocalStorage.saveItem<boolean>("container-labeled", buildLocalStorageKey(uniqueId),
        newExpandedState ? true : false);
    }
  }

  return (
    <div
      className="container-labeled-component shadow-sm"
    >
      <div
        className="container-label"
        onClick={onHeaderClick}
      >
        <HorizontalListComponent>
          {isExpanded ? <i className="bi bi-caret-up-fill" /> : <i className="bi bi-caret-down-fill"></i>}
          {label}
        </HorizontalListComponent>
      </div>

      <div
        className={classNames('container-content', {
          open: isExpanded,
          closed: !isExpanded,
        })}
      >
        {children}
      </div>

    </div>
  );
}
