import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CustomElement } from '../../types';
import { useRichTextEditorOperationsContext } from '../../contexts/operationsContext/hooks/useRichTextEditorOperationsContext';
import { ElementName } from '../../elements/information/elementName';
import { ElementConfiguration } from '../../elements/information/elementConfiguration';

import './richTextEditorElementToolbar.css';

export interface RichTextEditorElementToolbarProps {
  element: CustomElement;
}

export function RichTextEditorElementToolbarComponent({
  element
}: RichTextEditorElementToolbarProps): JSX.Element {

  const renderContext = useRichTextEditorOperationsContext();
  const [currentLabel, setCurrentLabel] = useState<string | undefined>(undefined);

  const [show, setShow] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const isConfigurable = ElementConfiguration.isEditable(element.type);

  const handleToggle = () => {
    setShow(!show);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShow(() => false);
    }
  };

  const handleActionConfigure = () => {
    if (!isConfigurable) {
      return;
    }

    setShow(false);
    renderContext.configureElementFunction(element);
  }

  const handleAddElementBefore = () => {
    renderContext.insertEmptyElementFunction(element, "before");
  }

  const handleAddElementAfter = () => {
    renderContext.insertEmptyElementFunction(element, "after");
  }

  const handleActionDelete = () => {
    renderContext.deleteElementFunction(element);
  }

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [show]);

  const getConfigElementLabel = () => {
    if (isConfigurable) {
      return "Configurar " + ElementName.getElementName(element.type);
    } else {
      return "Elemento sem opções";
    }
  }

  return <div
    contentEditable={false}
    style={{ position: "relative" }}
  >
    <Button
      size='sm'
      onClick={handleToggle}
      variant="outline-primary"
    >
      {isConfigurable &&
        <i className="bi bi-gear-fill"></i>
      }

      {isConfigurable === false &&
        <i className="bi bi-gear"></i>
      }
    </Button>

    {show && (
      <div
        className={"element-settings-floating-toolbar shadow rounded"}
        ref={menuRef}
      >
        <div className="label">
          {currentLabel}
        </div>

        <Button
          size='sm'
          onMouseEnter={() => setCurrentLabel(`Apagar ${ElementName.getElementName(element.type)}`)}
          onClick={handleActionDelete}
          variant="danger"
        >
          <i className="item delete bi bi-trash3-fill"></i>
        </Button>
        <div className="sep" />

        <Button
          size='sm'
          onMouseEnter={() => setCurrentLabel("Inserir linha abaixo")}
          onClick={handleAddElementAfter}
          variant='outline-primary'
        >
          <i className="item add-item bi bi-arrow-bar-down"></i>
        </Button>

        <Button
          size='sm'
          onMouseEnter={() => setCurrentLabel("Inserir linha acima")}
          onClick={handleAddElementBefore}
          variant='outline-primary'
        >
          <i className="item add-item bi bi-arrow-bar-up"></i>
        </Button>
        <div className="sep" />
        <Button
          size='sm'
          onMouseEnter={() => setCurrentLabel(getConfigElementLabel())}
          onClick={handleActionConfigure}
          variant='outline-primary'
        >
          <i className="item configure bi bi-gear-fill"></i>
        </Button>
      </div>
    )
    }
  </div >
}
