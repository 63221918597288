import { Editor, Transforms, Element } from 'slate';
import { ReactEditor } from 'slate-react';
import { Button, ButtonGroup, ButtonToolbar, Dropdown } from 'react-bootstrap';
import { WhiteSpaceComponent } from '../../../whiteSpace/whiteSpace';
import { RichTextEditorContentManager } from '../../richTextEditorContentManager';
import { MenuItemComponent } from '../../../menuItems/menuItem';
import { ButtonImage } from './toolbarButtons/buttonImage';
import {
  BlockTypes,
  BlockType,
  EditorUtil
} from '../../richTextEditorUtil';
import {
  CustomElement,
  CustomText,
  CustomElementTypesEnum,
} from '../../types';
import { ElementName } from '../../elements/information/elementName';

import './richTextEditorToolbar.css';

interface RichTextEditorToolbarProps {
  editor: Editor;
  contentManager: RichTextEditorContentManager,

  handleInsertUserImages: (images: string[]) => void,
  handleInsertUrl: () => void,
  handleInsertYoutubeVideo: () => void,
  handleInsertYoutubeShort: () => void,
  handleInsertUserQuery: () => void
}

export function RichTextEditorToolbarComponent({
  editor,
  contentManager,
  handleInsertUserImages,
  handleInsertUrl,
  handleInsertYoutubeVideo,
  handleInsertYoutubeShort,
  handleInsertUserQuery
}: RichTextEditorToolbarProps) {

  const isBlockActive = (format: CustomElement['type']) => {
    const [match] = Editor.nodes(editor, {
      match: n => Element.isElement(n) && n.type === format,
    });
    return !!match;
  };

  const toggleBlock = (format: CustomElement['type']) => {
    if (!BlockTypes.includes(format as BlockType)) {
      // If the format is not a block type, don't proceed
      return;
    }

    const isActive = isBlockActive(format);

    Transforms.setNodes(
      editor,
      { type: isActive ? CustomElementTypesEnum.Paragraph : format },
      {
        match: n => Element.isElement(n) && EditorUtil.canBlockTypeBeChanged(format),
      }
    );

    // Refocus the editor to keep the selection visible
    ReactEditor.focus(editor);
  };

  const isMarkActive = (editor: Editor, format: keyof Omit<CustomText, 'text'>) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const handleToggleMark = (editor: Editor, format: keyof Omit<CustomText, 'text'>) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }

    // Refocus the editor to keep the selection visible
    ReactEditor.focus(editor);
  };

  const handleClearFormatting = () => {

    // Remove all marks
    Editor.removeMark(editor, 'bold');
    Editor.removeMark(editor, 'italic');
    Editor.removeMark(editor, 'underline');
    Editor.removeMark(editor, 'strikethrough');

    // Remove all block types
    Transforms.setNodes(
      editor,
      { type: CustomElementTypesEnum.Paragraph },
      {
        match: n => Element.isElement(n) && EditorUtil.canBlockTypeBeChanged(n.type),
      }
    );

    // Refocus the editor to keep the selection visible
    ReactEditor.focus(editor);
  }

  return (

    <ButtonToolbar aria-label="Barra de ferramentas do editor" className="toolbar">
      <ButtonGroup className="me-2" aria-label="Formatação básica">
        <Button onClick={() => handleToggleMark(editor, 'bold')}><strong>B</strong></Button>
        <Button onClick={() => handleToggleMark(editor, 'italic')}><em>I</em></Button>
        <Button onClick={() => handleToggleMark(editor, 'underline')}><u>U</u></Button>
        <Button onClick={() => handleToggleMark(editor, 'strikethrough')}><div className='action-strikethrough'>S</div></Button>
      </ButtonGroup>

      <ButtonGroup className="me-2" aria-label="Formatação de linha">
        <Button onClick={() => toggleBlock(CustomElementTypesEnum.HeadingOne)}>H1</Button>
        <Button onClick={() => toggleBlock(CustomElementTypesEnum.HeadingTwo)}>H2</Button>
        <Button onClick={() => toggleBlock(CustomElementTypesEnum.HeadingThree)}>H3</Button>
        <Button onClick={() => toggleBlock(CustomElementTypesEnum.Paragraph)}>P</Button>
      </ButtonGroup>

      <ButtonGroup className="me-2" aria-label="Retirar formatações">
        <Button onClick={() => handleClearFormatting()}>Limpar formatação</Button>
      </ButtonGroup>

      <ButtonGroup className="me-2" aria-label="Inserir item">
        <ButtonImage
          contentManager={contentManager}
          handleImageInsert={(images) => {
            handleInsertUserImages(images);
          }}
        />
      </ButtonGroup>

      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          Inserir...<WhiteSpaceComponent ammount={2} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={handleInsertUrl}>
            <MenuItemComponent caption={ElementName.getElementName(CustomElementTypesEnum.Url)} iconName='link-45deg' />
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleInsertYoutubeVideo}>
            <MenuItemComponent caption={ElementName.getElementName(CustomElementTypesEnum.YoutubeVideo)} iconName='youtube' />
          </Dropdown.Item>
          <Dropdown.Item onClick={handleInsertYoutubeShort}>
            <MenuItemComponent caption={ElementName.getElementName(CustomElementTypesEnum.YoutubeShort)} iconName='youtube' />
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={handleInsertUserQuery}>

            <MenuItemComponent caption={ElementName.getElementName(CustomElementTypesEnum.UserQuery)} iconName='chat-left' />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

    </ButtonToolbar>
  )
};
