import { Button, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ServiceApiSites } from '../../../../../services/api/serviceApiSites';
import { TreeComponent } from '../../../../../common-frontend/components/tree/Tree';
import { TreeNode } from '../../../../../common/util/tree/treeNode';
import { TreeNodeUtil } from '../../../../../common/util/tree/treeNodeUtil';
import { SitePageEditorInfo } from '../../../../../common-app/sites/models/sitePageEditorInfo';
import { SitePageParentTreeNode } from '../../../../../common-app/sites/models/sitePageParentTreeNode';
import { PageNameComponent } from '../pageName/pageName';

interface SitePageSelectParentModalProps {
  show: boolean;
  siteId: string,
  pageId: string;
  onClose: () => void;
  onChangeParent: (pageId: string, newParentPageId: string) => void;
}

export function SitePageSelectParentModalComponent({
  show,
  siteId,
  pageId,
  onClose,
  onChangeParent
}: SitePageSelectParentModalProps): JSX.Element {

  const [pageInfo, setPageInfo] = useState<SitePageEditorInfo>();
  const [treeNodes, setTreeNodes] = useState<TreeNode<SitePageParentTreeNode>[]>([]);

  const [temporaryParentPageId, setTemporaryParentPageId] = useState<string>();

  // Refresh the temporary parent page id when the dialog is shown
  useEffect(() => {

    if (show === false) {
      return;
    }

    if (pageInfo !== undefined) {
      setTemporaryParentPageId(pageInfo.parentPageId);
    }

  }, [show]);

  // Load all the data when the page id changes
  useEffect(() => {

    const loadPages = async () => {

      const pages = await ServiceApiSites.getSitePagesParentTree(siteId, pageId);
      const treeNodes = TreeNodeUtil.buidlTreeFromList(
        pages,
        (node, parentNode) => node.parentPageId === parentNode.id,
        (node) => node.parentPageId === "" || node.parentPageId === null,
        (node) => node.id,
        (node) => node.name,
        (node) => node.availableToSelection
      );

      const siteTreeNode = new SitePageParentTreeNode();
      siteTreeNode.name = "Site";
      siteTreeNode.id = "";

      const siteNode = new TreeNode(siteTreeNode, "", "Site", true);
      siteNode.children = treeNodes;

      setTreeNodes([siteNode]);
    }

    const loadPageInfo = async () => {
      const page = await ServiceApiSites.getPageEditorInfo(siteId, pageId);
      setPageInfo(page);

      if (page !== undefined) {
        setTemporaryParentPageId(page.parentPageId);
      }
    }

    loadPages();
    loadPageInfo();

  }, [pageId, show])

  // Control the confirm button 
  const getConfirmChoiceEnabled = () => {
    return temporaryParentPageId === undefined || temporaryParentPageId === pageInfo?.parentPageId;
  }

  // Send the results back
  const handleConfirmChoice = () => {
    if (temporaryParentPageId === undefined) {
      return;
    }

    onChangeParent(pageId, temporaryParentPageId);
  }

  return (
    <div className="-site-page-select-parent-modal-component">
      <Modal
        show={show}
        centered
      >
        <Modal.Header>
          <Modal.Title>Escolha o novo parente</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          {pageInfo &&
            <TreeComponent
              items={treeNodes}
              initialSelectedItemId={pageInfo.parentPageId}
              selectedItem={temporaryParentPageId}
              onSelect={(itemId) => {

                if (temporaryParentPageId === itemId) {
                  setTemporaryParentPageId(undefined);
                }
                else {
                  setTemporaryParentPageId(itemId);
                }
              }}
              renderNodeText={(node) => <PageNameComponent name={node.name} />}
            />
          }
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={onClose}>
            Fechar
          </Button>
          <Button
            variant="primary"
            disabled={getConfirmChoiceEnabled()}
            onClick={handleConfirmChoice}>
            Escolher
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}
