
interface VerticalWhiteSpaceProps {
  ammount: number;
}

export function VerticalWhiteSpaceComponent({ ammount }: VerticalWhiteSpaceProps): JSX.Element {
  return (
    <>
      {Array(ammount).fill(0).map((_, index) => (
        <div style={{
          minHeight: '2rem'
        }}
          key={index}>
        </div>))}
    </>
  );
}