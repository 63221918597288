import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { ElementSettingsCreationProps } from '../../elementSettingsCreationProps';
import { ElementConfigurationSnippetTextInputComponent } from '../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';
import { ElementConfigurationSnippetYoutubeShortPreviewComponent } from '../../snippets/elementConfigurationSnippetYoutubeShortPreview/elementConfigurationSnippetYoutubeShortPreview';
import { ElementConfigurationSnippetYoutubeContentComponent } from '../../snippets/elementConfigurationSnippetYoutubeContent/elementConfigurationSnippetYoutubeContent';
import { ElementCreationRef } from '../../elementSettingsConfig';
import {
  CustomElement,
  CustomElementTypesEnum,
  CustomElementYoutubeShort,
  CustomElementYoutubeVideoVersion
} from '../../../types';

export const ElementCreationYoutubeShortComponent = forwardRef<ElementCreationRef, ElementSettingsCreationProps>(
  (props, ref): JSX.Element => {

    const [videoId, setVideoId] = useState<string>("");
    const [caption, setCaption] = useState<string>("");

    useEffect(() => {

      const canBeCreated = videoId.length > 0;
      props.elementCanBeCreated(canBeCreated);

    }, [videoId]);

    // Function that needs to be called from the parent component
    const internalCreateElement = (): CustomElement | undefined => {

      const youtubeShortNode: CustomElementYoutubeShort = {
        type: CustomElementTypesEnum.YoutubeShort,
        version: CustomElementYoutubeVideoVersion,
        videoId: videoId,
        caption: caption,
        children: [{ text: '' }],
      };

      return youtubeShortNode;
    }

    useImperativeHandle(ref, () => ({
      createElement: internalCreateElement,
    }));

    return (
      <div className="element-creation-url-component">

        <VerticalListComponent>

          <ElementConfigurationSnippetYoutubeContentComponent
            videoId={videoId}
            setVideoId={setVideoId}
          />

          <ElementConfigurationSnippetYoutubeShortPreviewComponent
            videoId={videoId}
          />

          <ElementConfigurationSnippetTextInputComponent
            caption='Legenda'
            placeholder=''
            value={caption}
            onChange={
              (value) => setCaption(value)
            }
          />

        </VerticalListComponent>
      </div>
    );
  });
