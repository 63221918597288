import { PageSettingsComponent } from '../../pages/pageSettings/pageSettings';
import { SiteSettingsComponent } from '../../site/siteSettings/siteSettings';

import './siteEditorMainArea.css';

interface SiteEditorMainAreaProps {
  siteId: string;
  selectedItemId: string;
  callbackPagesChanged: () => void;
}

export function SiteEditorMainAreaComponent({
  siteId,
  selectedItemId,
  callbackPagesChanged
}: SiteEditorMainAreaProps): JSX.Element {
  return (
    <div className="site-editor-main-area-component">

      {selectedItemId === siteId &&
        <div>
          <SiteSettingsComponent
            siteId={siteId}
            key={siteId}
          />
        </div>
      }

      {selectedItemId !== siteId &&
        <PageSettingsComponent
          key={selectedItemId}
          siteId={siteId}
          pageId={selectedItemId}
          callbackPagesChanged={callbackPagesChanged}
        />
      }
    </div>
  );
}
