import { useMemo } from "react";
import { Container } from "react-bootstrap";
import { PageTitle } from "../common-frontend/components/pageTitle/pageTitle";
import { ContactUsComponent } from "../components/home/contactUs/contactUs";
import { VerticalWhiteSpaceComponent } from "../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace";
import { DynamicPageContactsServiceProvider } from "../components/sites/rendering/dynamicPages/contacts/dynamicPageContactsServiceProvider";

export function PageContact(): JSX.Element {

  const dataProvider = useMemo(() => new DynamicPageContactsServiceProvider(), []);

  // #83 - Find a way to get the pageId from the company name
  //const pageId = "8aa6e0bc-70fd-4b6e-a89b-2e1c7a7149e4";

  return (
    <div className="contacts-page">
      <Container>
        <PageTitle title="Contactos" />

        <ContactUsComponent />

        <VerticalWhiteSpaceComponent ammount={3} />

      </Container>
    </div>
  );
}