import { useEffect, useState } from 'react';
import { SectionTitleComponent } from '../../../common-frontend/components/sectionTitle/sectionTitle';
import { UserQueryComponent } from '../../../common-frontend/components/userQuery/userQuery';
import { VerticalListComponent } from '../../../common-frontend/components/verticalList/verticalList';
import { ServiceApiPageDataContacts } from '../../../services/api/serviceApiPageDataContacts';

import './contactUs.css';

export function ContactUsComponent(): JSX.Element {

    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [mail, setMail] = useState<string>("");

    useEffect(() => {
        (async () => {
            const mainContact = await ServiceApiPageDataContacts.getMainContact();
            setPhoneNumber(mainContact?.phone01 ?? "(+351) 928 045 204");
            setMail(mainContact?.mail01 ?? "pixelvaidoso@gmail.com");
        })();
    }, []);

    return (
        <div className="contact-us-component">
            <div className='parent'>

                <SectionTitleComponent>
                    Entre em contacto connosco
                </SectionTitleComponent>

                <VerticalListComponent>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "1rem",
                            width: "100%"
                        }}>

                        <div
                            className='option'
                        >
                            <VerticalListComponent>
                                <div className='title'>
                                    Por correio electrónico...
                                </div>
                                <a href={`mailto:${mail}`}>{mail}</a>

                            </VerticalListComponent>
                        </div>

                        <div
                            className='option'
                        >
                            <VerticalListComponent>
                                <div className='title'>
                                    Por telefone...
                                </div>
                                <div>
                                    {phoneNumber}
                                </div>
                            </VerticalListComponent>
                        </div>

                    </div>

                    <div
                        className='option'>
                        <VerticalListComponent>
                            <div
                                className='title'>...ou deixe-nos uma mensagem
                            </div>
                            <UserQueryComponent
                                siteId='some-site-id' // Nuno
                                pageId='some-page-id' // Nuno
                                userQueryId='some-user-query-id' // Nuno
                            />
                        </VerticalListComponent>
                    </div>
                </VerticalListComponent>
            </div>
        </div>
    );
}
