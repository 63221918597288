import { useEffect, useState } from "react";
import { Contact } from "../../../../../common-app/pagesContacts/models/contact";
import { ContactComponent } from "../contact/contact";
import { DynamicPageContactsProvider } from "../provider/dynamicPageContactsProvider";

import "./contacts.css";

interface ContactsProps {
  pageId: string;
  contactsProvider: DynamicPageContactsProvider;
}

export function ContactsComponent({ pageId, contactsProvider }: ContactsProps): JSX.Element {

  const [contacts, setContacts] = useState<Contact[]>([]);

  useEffect(() => {

    async function fetchData() {
      const contacts = await contactsProvider.getContacts(pageId);
      setContacts(contacts);
    }

    fetchData();
  }, [contactsProvider]);

  return (
    <div className="contacts-component"
    >
      {
        contacts.map((contact) => (
          <ContactComponent
            key={contact.id}
            contact={contact}
          />
        ))
      }
    </div>
  )
}