import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ElementConfigurationSnippetImageSelectionComponent } from '../../snippets/elementConfigurationSnippetImageSelection/elementConfigurationSnippetImageSelection';
import { ElementConfigurationSnippetImagePreviewComponent } from '../../snippets/elementConfigurationSnippetImagePreview/elementConfigurationSnippetImagePreview';
import { ElementConfigurationSnippetTextInputComponent } from '../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';
import { CustomElement, CustomElementImageUser } from '../../../types';
import { ElementSettingsEditProps } from '../../elementSettingsEditProps';
import { ElementEditRef } from '../../elementSettingsConfig';

import './elementEditImageUser.css';

export const ElementEditImageUserComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({
    contentManager,
    element,
    elementCanBeUpdated
  }, ref): JSX.Element => {

    const elementImageUser = element as CustomElementImageUser;

    const [imageId, setImageId] = useState(elementImageUser.image.imageId);
    const [caption, setCaption] = useState(elementImageUser.image.caption);

    useImperativeHandle(ref, () => ({
      updateElement: (element: CustomElement) => {
        const updatedElement = element as CustomElementImageUser;
        updatedElement.image.imageId = imageId;
        updatedElement.image.caption = caption;
        return updatedElement;
      },
    }));

    useEffect(() => {
      elementCanBeUpdated(imageId !== '');
    }, [imageId]);

    return (
      <div className="element-edit-image-user-component">

        <ElementConfigurationSnippetImagePreviewComponent
          getImagesInfoFunction={contentManager.getImagesInfoFunction}
          imageId={imageId}
        />

        <ElementConfigurationSnippetImageSelectionComponent
          uploadImageFunction={contentManager.uploadImageFunction}
          getImagesListFunction={contentManager.getImagesListFunction}
          onImageSelected={setImageId}
        />

        <ElementConfigurationSnippetTextInputComponent
          caption="Legenda"
          value={caption}
          onChange={setCaption}
        />
      </div>
    );
  }
);