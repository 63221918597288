import { UserQueryAdminList } from '../../../../common-app/userQueries/models/userQueryAdminList';
import { VerticalListComponent } from '../../../../common-frontend/components/verticalList/verticalList';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { LabelAndValueComponent } from '../../../../common-frontend/components/labelAndValue/labelAndValue';
import { ContainerComponent } from '../../../../common-frontend/components/container/container';

import './adminUserQueryDetail.css';

interface AdminUserQueryDetailProps {
    item: UserQueryAdminList | undefined;
}

export function AdminUserQueryDetailComponent({ item }: AdminUserQueryDetailProps): JSX.Element {
    return (
        <div className="admin-user-query-detail-component">
            <ContainerComponent>
                {item &&
                    <VerticalListComponent>
                        <HorizontalListComponent>
                            <div style={{ flexGrow: "1" }}>
                                <LabelAndValueComponent
                                    label="Contacto"
                                    value={item.contact}
                                />
                            </div>

                            <div>
                                <LabelAndValueComponent
                                    label="Data"
                                    value={new Date(item.creationDate).toLocaleString()}
                                />
                            </div>

                        </HorizontalListComponent>

                        <LabelAndValueComponent
                            label="Assunto"
                            value={item.subject}
                        ></LabelAndValueComponent>

                        <LabelAndValueComponent
                            label="Mensagem"
                            value={item.message}
                        ></LabelAndValueComponent>
                    </VerticalListComponent>
                }
            </ContainerComponent>
        </div >
    );
}
