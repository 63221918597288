import { Button } from 'react-bootstrap';

import './siteAdminControlButton.css';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { SitesUtil } from '../../../common/sites/sitesUtil';

export function SiteAdminControlButtonComponent(): JSX.Element {

    const location = useLocation();

    const siteAdminUrl = SitesUtil.getSiteAdminUrl(location.pathname);

    return (
        <div className="site-admin-control-button-component">
            <Link to={siteAdminUrl}>
                <Button
                    className='action-button'
                    variant="primary"
                >
                    <i
                        className="bi bi-gear"
                        style={{ fontSize: '1.5rem' }}
                    />
                </Button>
            </Link>
        </div>
    );
}
