import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { CustomElement, CustomElementTypesEnum, CustomElementUrl, CustomElementUrlVersion } from '../../../types';
import { ElementSettingsCreationProps } from '../../elementSettingsCreationProps';
import { ElementConfigurationSnippetTextInputComponent } from '../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';
import { ElementCreationRef } from '../../elementSettingsConfig';
import { ElementConfigurationSnippetUrlComponent } from '../../snippets/elementConfigurationSnippetUrl/elementConfigurationSnippetUrl';

export const ElementCreationUrlComponent = forwardRef<ElementCreationRef, ElementSettingsCreationProps>(
  (props, ref): JSX.Element => {

    const [url, setUrl] = useState<string>("");
    const [text, setText] = useState<string>("");

    useEffect(() => {

      const canBeCreated = url.length > 0 && text.length > 0;
      props.elementCanBeCreated(canBeCreated);

    }, [url, text]);

    // Function that needs to be called from the parent component
    const internalCreateElement = (): CustomElement | undefined => {

      const linkNode: CustomElementUrl = {
        type: CustomElementTypesEnum.Url,
        version: CustomElementUrlVersion,
        url,
        children: [{ text: text }],
      };

      return linkNode;
    }

    useImperativeHandle(ref, () => ({
      createElement: internalCreateElement,
    }));

    return (
      <div className="element-creation-url-component">

        <VerticalListComponent>
          <ElementConfigurationSnippetUrlComponent
            url={url}
            setUrl={setUrl}
          />
          <ElementConfigurationSnippetTextInputComponent
            caption='Texto'
            placeholder='Clique aqui'
            value={text}
            onChange={
              (value) => setText(value)
            }
          />
        </VerticalListComponent>
      </div>
    );
  });
