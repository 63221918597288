import './siteName.css';

interface SiteNameProps {
  name: string;
}

export function SiteNameComponent({ name }: SiteNameProps): JSX.Element {
  return (
    <div className="site-name-component">

      <i className="bi bi-globe2" />
      {name}
    </div>
  );
}