import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { HorizontalListComponent } from '../../../../../common-frontend/components/horizontalList/horizontalList';
import { VerticalListComponent } from '../../../../../common-frontend/components/verticalList/verticalList';
import { FormEditComponent } from '../../../../../common-frontend/components/form/formEdit/formEdit';
import { SitePageEditorInfo } from '../../../../../common-app/sites/models/sitePageEditorInfo';
import { ServiceApiSites } from '../../../../../services/api/serviceApiSites';
import { TypeUpdate } from '../../../../../common/util/types/typeUpdate';
import { useAutoSave } from '../../../../../common-frontend/hooks/autoSave/autoSave';
import { ContainerLabeledComponent } from '../../../../../common-frontend/components/containerLabeled/containerLabeled';

import './pageCommonSettings.css';

interface PageCommonSettingsProps {
  siteId: string;
  pageId: string;
  callbackPagesChanged: () => void;
}

export function PageCommonSettingsComponent({
  siteId,
  pageId,
  callbackPagesChanged
}: PageCommonSettingsProps): JSX.Element {

  const savePageData = (pageData: SitePageEditorInfo): boolean => {

    ServiceApiSites.updateSitePage(
      siteId,
      pageId,
      pageData.name,
      pageData.isActive).then((result) => {

        if (result == true) {
          callbackPagesChanged();
        }
      });

    return true;
  };

  const {
    data: pageData,
    updateData: updatePageData
  } = useAutoSave<SitePageEditorInfo>(savePageData)

  useEffect(() => {
    const loadPageData = async () => {
      const pageEditData = await ServiceApiSites.getPageEditorInfo(siteId, pageId);
      if (pageEditData) {
        updatePageData(pageEditData, true);
      }
    }

    loadPageData();

  }, [siteId, pageId]);

  return (
    <div className="page-common-settings-component">
      <ContainerLabeledComponent
        label='Página'
        uniqueId={`page-settings-${pageId}`}
      >
        {pageData &&
          <VerticalListComponent>
            <HorizontalListComponent>
              <label
                className='label'
                htmlFor="siteName">Nome
              </label>
              <FormEditComponent
                id="siteName"
                className='input'
                type="text"
                value={pageData.name}
                onChange={(value) => {
                  updatePageData(TypeUpdate.updateObjectField(pageData, 'name', value))
                }}
              />
            </HorizontalListComponent>

            <HorizontalListComponent>
              <label
                className='label'
                htmlFor="siteActive">Visível
              </label>
              <Form.Check
                type="switch"
                id="siteActive"
                checked={pageData.isActive}
                onChange={(e) => {
                  updatePageData(TypeUpdate.updateObjectField(pageData, 'isActive', e.target.checked))
                }}
              />
            </HorizontalListComponent>
          </VerticalListComponent >
        }
      </ContainerLabeledComponent>
    </div >
  );
}
