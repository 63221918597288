import { SectionTitleComponent } from '../../../common-frontend/components/sectionTitle/sectionTitle';

import './whyUs.css';

export function WhyUsComponent(): JSX.Element {
    return (
        <div className="why-us-component">

            <SectionTitleComponent>
                Porque nos deve escolher a nós
            </SectionTitleComponent>

            <div className='content'>

                <div className='content-item'>
                    <div className='content-item-title'>
                        Disponibilidade
                    </div>
                    <div className='content-item-body'>
                        Estamos sempre disponíveis para explicar todos os detalhes do seu site e para o ajudar a tomar as melhores decisões.
                        Nunca estará sem suporte ou a falar com um robot.
                    </div>
                </div>

                <div className='content-item'>
                    <div className='content-item-title'>
                        Expectativas
                    </div>
                    <div className='content-item-body'>
                        Não queremos que se sinta perdido sem saber o que se passa. Vamos explicar-lhe tudo o que está a acontecer e o que pode esperar.
                    </div>
                </div>

                <div className='content-item'>
                    <div className='content-item-title'>
                        Empatia
                    </div>
                    <div className='content-item-body'>
                        Sabemos que o seu foco é no seu negócio, e não na tecnologia. Nós sabemos fazer essa ponte e nunca se sentirá perdido enquanto trabalha connosco.
                    </div>
                </div>

                <div className='content-item'>
                    <div className='content-item-title'>
                        Preço
                    </div>
                    <div className='content-item-body'>
                        Todos os custos serão apresentados de forma clara e transparente. Não existem custos escondidos e todos os valores são apresentados previamente.
                    </div>
                </div>

                <div className='content-item'>
                    <div className='content-item-title'>
                        Suporte
                    </div>
                    <div className='content-item-body'>
                        Somos uma equipa de pessoas que quer ajudar pessoas. Estamos disponíveis para falar consigo para perceber o que necessita e como podemos ajudar.
                    </div>
                </div>

                <div className='content-item'>
                    <div className='content-item-title'>
                        Princípios
                    </div>
                    <div className='content-item-body'>
                        Acreditamos que a honestidade e a transparência são fundamentais para o sucesso de qualquer projecto. Nunca tentaremos vender-lhe algo que não necessita.
                    </div>
                </div>
            </div>
        </div>
    );
}
