import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { FormEditComponent } from '../../../common-frontend/components/form/formEdit/formEdit';
import { useState } from 'react';
import { HorizontalListComponent } from '../../../common-frontend/components/horizontalList/horizontalList';
import { VerticalListComponent } from '../../../common-frontend/components/verticalList/verticalList';
import { SitesUtil } from '../../../common/sites/sitesUtil';
import { ServiceApiSites } from '../../../services/api/serviceApiSites';
import { GuidUtil } from '../../../common/util/guid/guid';
import { useUserSessionContext } from '../../contexts/session/hooks/useUserSessionContext';

import './createSiteAction.css';

export function CreateSiteActionComponent(): JSX.Element {

  const navigate = useNavigate();
  const userSessionContext = useUserSessionContext();

  const [websiteName, setWebsiteName] = useState<string>("");

  const handleCreateWebsite = async (event: React.FormEvent) => {
    event.preventDefault();

    const newSiteId = GuidUtil.GenerateNewGuid();

    const result = await ServiceApiSites.create1ClickSite(newSiteId, websiteName, userSessionContext.userId);
    if (result === false) {
      console.error("Error creating website");
      return;
    }

    const siteDomain = await ServiceApiSites.getSiteDomain(newSiteId);
    if (siteDomain === undefined) {
      console.error("Error creating website");
      return
    }

    const siteHomePage = SitesUtil.getWebsiteHomeUrl(siteDomain);
    navigate(siteHomePage);
  }

  return (
    <div className="create-site-action-component">
      <Form onSubmit={(e) => { handleCreateWebsite(e) }}>
        <VerticalListComponent>
          <div className="message">
            Crie o seu site com apenas um clique
          </div>

          <HorizontalListComponent>
            <FormEditComponent
              type="text"
              value={websiteName}
              size='lg'
              placeHolder="Nome do seu site"
              onChange={setWebsiteName}
            />

            <Button
              size="lg"
              variant="primary"
              disabled={websiteName.trim() === ""}
              onClick={handleCreateWebsite}>
              Criar
            </Button>
          </HorizontalListComponent>
        </VerticalListComponent>
      </Form>
    </div>
  );
}
