import { SortableList } from '../sortableList/sortableList';
import './itemsList.css';

interface ItemsListProps<T> {
  items: T[];
  listName: string;
  getItemKey: (item: T) => string;
  getItemLabel: (item: T) => string;
  selectedItemKey: string | undefined;
  onSelectedItemChanged: (itemKey: string) => void;

  allowSorting: boolean;
  onItemsReordered: ((items: T[]) => void) | undefined;
  onFinihedSorting: (() => void) | undefined;
}

export function ItemsListComponent<T>({
  items,
  listName,
  getItemKey,
  getItemLabel,
  selectedItemKey,
  onSelectedItemChanged,
  allowSorting,
  onItemsReordered,
  onFinihedSorting
}: ItemsListProps<T>): JSX.Element {

  const getItemClassName = (itemKey: string): string => {

    if (selectedItemKey === itemKey) {
      return 'item selected';
    }
    return 'item';
  }

  const renderListItem = (item: T): JSX.Element => {

    const itemKey = getItemKey(item);

    return <button
      className={getItemClassName(itemKey)}
      key={getItemKey(item)}
      onClick={() => onSelectedItemChanged(itemKey)}
    >
      {getItemLabel(item)}
    </button>
  }

  if (allowSorting && onItemsReordered) {

    return (
      <SortableList
        items={items}
        listId={listName}
        listDirection="vertical"

        getItemKey={getItemKey}
        onItemsReordered={onItemsReordered}

        renderContainer={
          (children: JSX.Element) => {
            return (
              <div className="items-list-component rounded">
                {children}
              </div>);
          }}
        renderElement={renderListItem}
        onDropComplete={onFinihedSorting}
      />
    )
  }

  return (
    <div className="items-list-component rounded">
      {
        items.map((item) => renderListItem(item))
      }
    </div>
  );
}
