import { Container } from "react-bootstrap";
import { PageTitle } from "../../common-frontend/components/pageTitle/pageTitle";
import { AlertInlineComponent } from "../../common-frontend/components/alertInline/alertInline";
import { Link } from "react-router-dom";
import { VerticalWhiteSpaceComponent } from "../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace";

import './pageServices.css';

export function PageServices(): JSX.Element {
  return (

    <Container>

      <div className="page-services">
        <PageTitle title="Serviços"></PageTitle>

        <div className="group">
          <h3>O que fazemos</h3>
          <p>
            Pode contar com a Pixel Vaidoso caso deseje:
          </p>
          <ul>
            <li>Desenvolver um Website</li>
            <li>Desenvolver uma Aplicação Web</li>
            <li>Desenvolver uma Aplicação Desktop</li>
            <li>Não sabe bem o que precisa ou se podemos ajudar?<br /> Não faz mal. Estamos aqui para o ouvir e discutir ideias.</li>
          </ul>
        </div>

        <VerticalWhiteSpaceComponent ammount={1} />

        <div className="shadow">
          <AlertInlineComponent variant="info">
            <div>
              Precisa de um website simples? Experimente o nosso sistema <Link to="/1-click">1-click</Link> para criar websites num minuto, facilmente, e sem custos.
            </div>
          </AlertInlineComponent>
        </div>

        <VerticalWhiteSpaceComponent ammount={1} />

        <div className="group">
          <h3>Como trabalhamos</h3>
          <p>
            Este é o nosso processo de trabalho:
          </p>
          <ul>
            <li>Reunião inicial para recolher requisitos</li>
            <li>Elaboração de orçamento</li>
            <li>Desenvolvimento de protótipo</li>
            <li>Reunião para validação do protótipo</li>
            <li>Desenvolvimento da aplicação ou site</li>
            <li>Testes</li>
            <li>Entrega do produto</li>
          </ul>
        </div>

        <VerticalWhiteSpaceComponent ammount={1} />

        <h5><Link to={"/contacts"}>Contacte-nos</Link> para vermos como podemos ajudá-lo.</h5>

        <VerticalWhiteSpaceComponent ammount={2} />

      </div>
    </Container>

  );
}