import { ApiService } from "../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import {
  GetPageDataStaticResponse,
  GetPageDataStaticRouteDef
} from "../../common-app/pagesStatic/actions/getPageDataStatic";
import {
  UpdatePageDataStaticRequestBody,
  UpdatePageDataStaticRouteDef
} from "../../common-app/pagesStatic/actions/updatePageDataStatic";
import { RequestHeaderHelper } from "../requestHeaderData/requestHeaderHelper";

export class ServiceApiPageDataStatic extends ApiService {

  static async getPageDataStatic(pageId: string): Promise<string> {
    const routeDef = new GetPageDataStaticRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetPageDataStaticResponse>(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return JSON.stringify([]);
    }

    return response.contents;
  }

  static async updatePageDataStatic(siteId: string, pageId: string, contents: string): Promise<boolean> {
    const routeDef = new UpdatePageDataStaticRouteDef();
    const requestBody = new UpdatePageDataStaticRequestBody(contents);
    const requestOptions = this.getRequestOptions(routeDef, requestBody);

    return this.DoRequest(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }
}
