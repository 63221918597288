import { CustomElementTypesEnum } from "../../types";

export class ElementName {
  static getElementName(elementType: CustomElementTypesEnum): string {
    switch (elementType) {
      case CustomElementTypesEnum.Paragraph:
        return "Parágrafo";
      case CustomElementTypesEnum.HeadingOne:
        return "Título 1";
      case CustomElementTypesEnum.HeadingTwo:
        return "Título 2";
      case CustomElementTypesEnum.HeadingThree:
        return "Título 3";
      case CustomElementTypesEnum.Image:
        return "Imagem";
      case CustomElementTypesEnum.ImageUser:
        return "Imagem";
      case CustomElementTypesEnum.ImageUserGallery:
        return "Galeria";
      case CustomElementTypesEnum.Url:
        return "Link";
      case CustomElementTypesEnum.YoutubeVideo:
        return "Vídeo do Youtube";
      case CustomElementTypesEnum.YoutubeShort:
        return "Curta do Youtube";
      case CustomElementTypesEnum.UserQuery:
        return "Mensagem";
      default:
        return "Elemento desconhecido";
    }
  }
}