import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { TypeUpdate } from "../../../../../../common/util/types/typeUpdate";
import { ContainerLabeledComponent } from "../../../../containerLabeled/containerLabeled";
import { VerticalListComponent } from "../../../../verticalList/verticalList";
import { CustomElement, CustomElementImageUserGallery, ElementImage } from "../../../types";
import { ElementSettingsEditProps } from "../../elementSettingsEditProps";
import { ElementConfigurationSnippetGalleryImagesCaptionsComponent } from "../../snippets/elementConfigurationSnippetGalleryConfig/elementConfigurationSnippetGalleryImagesCaptions";
import { ElementConfigurationSnippetGallerySelectionComponent } from "../../snippets/elementConfigurationSnippetGallerySelection/elementConfigurationSnippetGallerySelection";
import { ElementConfigurationSnippetTextInputComponent } from "../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput";
import { ElementEditRef } from "../../elementSettingsConfig";

import './elementEditImageUserGallery.css';

export const ElementEditImageUserGalleryComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({
    contentManager,
    element,
    elementCanBeUpdated
  }, ref): JSX.Element => {

    const elementImageUserGallery = element as CustomElementImageUserGallery;

    const [localElement, setLocalElement] = useState<CustomElementImageUserGallery>(structuredClone(elementImageUserGallery));

    useImperativeHandle(ref, () => ({
      updateElement: (_: CustomElement) => {
        return localElement;
      },
    }));

    useEffect(() => {
      elementCanBeUpdated(localElement.images.length > 0);
    }, [localElement]);

    const handleImagesUpdated = (imageIds: string[]) => {

      // We have a new list of images to put on the element
      // But we need to merge correctly, because the existing items have
      // more information than just the imageId, like the caption.

      const updatedElementImages =
        imageIds.map(imageId => {

          // Check if we already have this image in the element
          const existingImage = elementImageUserGallery.images.find(image => image.imageId === imageId);
          if (existingImage) {
            return existingImage;
          }

          // If we don't have this image, we need to create a new one
          const newImage: ElementImage = {
            imageId,
            caption: ''
          };

          return newImage;
        });

      setLocalElement(
        TypeUpdate.updateObjectField(
          localElement,
          'images',
          updatedElementImages
        )
      )
    }

    return (

      <div className="element-edit-image-user-gallery-component">

        <VerticalListComponent>

          <ContainerLabeledComponent
            label="Imagens"
          >
            <ElementConfigurationSnippetGallerySelectionComponent
              uploadImageFunction={contentManager.uploadImageFunction}
              getImagesListFunction={contentManager.getImagesListFunction}
              getImagesInfoFunction={contentManager.getImagesInfoFunction}
              onImagesSelected={handleImagesUpdated}
              imagesSelected={localElement.images.map(image => image.imageId)}
            />
          </ContainerLabeledComponent>

          <ContainerLabeledComponent
            label="Legenda da galeria"
          >
            <ElementConfigurationSnippetTextInputComponent
              caption='Legenda'
              value={localElement.caption}
              onChange={
                (value) => setLocalElement(TypeUpdate.updateObjectField(localElement, 'caption', value))
              }
            />
          </ContainerLabeledComponent>

          <ContainerLabeledComponent
            label="Legendas das imagens">

            <ElementConfigurationSnippetGalleryImagesCaptionsComponent
              elementImageUserGallery={localElement}
              updateElementImageUserGallery={setLocalElement}
              getImagesInfoFunction={contentManager.getImagesInfoFunction}
            />
          </ContainerLabeledComponent>
        </VerticalListComponent>

      </div>
    )
  }
);
