import { SitePageType } from '../../../../../common-app/sites/enums/sitePageType';
import { DynamicPageContentsEditMapping } from '../dynamicPageContentsEditMapping';

interface PageContentsEditorProps {
  siteId: string;
  pageId: string;
  pageType: SitePageType;
  callbackPageContentsChanged: () => void;
}

export function PageContentsEditorComponent({
  siteId,
  pageId,
  pageType,
  callbackPageContentsChanged,
}: PageContentsEditorProps): JSX.Element {

  const ComponentToRender = DynamicPageContentsEditMapping.getPageContentsEditComponent(pageType);

  return (
    <div className="page-data-editor-component">

      {ComponentToRender &&
        <ComponentToRender
          siteId={siteId}
          pageId={pageId}
          callbackPageContentsChanged={callbackPageContentsChanged}
        />
      }
    </div>
  );
}
