import { forwardRef, useImperativeHandle } from 'react';
import { ElementSettingsEditProps } from '../../elementSettingsEditProps';
import { ElementEditRef } from '../../elementSettingsConfig';

export const ElementEditNoneComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({element}, ref): JSX.Element => {

    useImperativeHandle(ref, () => ({
      updateElement: (element) => { return element; },
    }));

    return (
      <div className="element-edit-none-component">
        <p>
          Este elemento não tem configurações extra
        </p>

        <p>
          {JSON.stringify(element)}
        </p>
      </div>
    );
  }
);
