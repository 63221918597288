import { DynamicPageBlogPostComponent } from './dynamicPageBlogPost';
import { DynamicPageBlogPostsListComponent } from './dynamicPageBlogPostsList';
import { BlogRouteParams } from './blogRouteParams';
import { useQueryParam } from '../../../hooks/routes/useQueryParam';
import { DynamicPageBlogProvider } from './provider/dynamicPageBlogProvider';
import { SitePageRenderInfo } from '../../../../common-app/sites/models/sitePageRenderInfo';

import './dynamicPageBlog.css';

interface DynamicPageBlogProps {
  siteId: string;
  pageId: string;
  pageRenderInfo: SitePageRenderInfo;
  dataProvider: DynamicPageBlogProvider
}

export function DynamicPageBlogComponent({
  siteId,
  pageId,
  dataProvider
}: DynamicPageBlogProps): JSX.Element {

  const postId = useQueryParam(BlogRouteParams.postId);

  return (
    <div>

      {/* Rendering of a Single Post */}
      {postId &&
        <div>
          <DynamicPageBlogPostComponent
            dataProvider={dataProvider}
            siteId={siteId}
            pageId={pageId}
            postId={postId}
          />
        </div>
      }

      {/* Rendering of a List of Posts */}
      {!postId &&
        <div>
          <div>
            <DynamicPageBlogPostsListComponent
              dataProvider={dataProvider}
              siteId={siteId}
              pageId={pageId}
            />
          </div>
        </div>
      }
    </div>
  );
}