import { SitePageFeature } from "../../../../common-app/sites/enums/sitePageFeature";
import { CustomElement, CustomElementTypesEnum } from "../../../../common-frontend/components/richTextEditor/types";

export class PageFeaturesCollect {
  public static getPageFeaturesFromRichTextEditorContent(editorContent: string): SitePageFeature[] {

    if (editorContent === "") {
      return [];
    }

    const features = new Set<SitePageFeature>();

    const items: CustomElement[] = JSON.parse(editorContent);

    for (let curItem = 0; curItem < items.length; curItem++) {
      const item: CustomElement = items[curItem];
      if (item.type === CustomElementTypesEnum.UserQuery) {
        features.add(SitePageFeature.UserQuery);
      }
    }

    return Array.from(features);
  }
}