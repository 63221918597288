import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GuidUtil } from '../../../../../common/util/guid/guid';
import { ArrayUtil } from '../../../../../common/util/array/arrayUtil';
import { ListItem, ListItemHelper } from '../../../../../common-frontend/library/list/listItem';
import { ListAndItemComponent } from '../../../../../common-frontend/components/listAndItem/listAndItem';
import { VerticalListComponent } from '../../../../../common-frontend/components/verticalList/verticalList';
import { ItemsListComponent } from '../../../../../common-frontend/components/itemsList/itemsList';
import { ContainerLabeledComponent } from '../../../../../common-frontend/components/containerLabeled/containerLabeled';
import { useSelectAnItemFromList } from '../../../../../common-frontend/hooks/items/itemSelection';
import { ContactFactory } from '../../../../../common-app/pagesContacts/models/factories/contactFactory';
import { Contact } from '../../../../../common-app/pagesContacts/models/contact';
import { ContactFormComponent } from '../../../../../pages/admin/contacts/contactForm';
import { ServiceApiPageDataContacts } from '../../../../../services/api/serviceApiPageDataContacts';
import { DynamicPageContentsEditProps } from '../dynamicPageContentsEditMapping';

import './pageContentsEditorContacts.css';

type ListItemContact = ListItem<Contact>;

export function PageContentsEditorContactsComponent({
  siteId,
  pageId
}: DynamicPageContentsEditProps): JSX.Element {

  const [items, setItems] = useState<ListItemContact[]>([]);
  const [selectedItemKey, setSelectedItemKey] = useState<string | undefined>(undefined);

  const [selectContactFromList] = useSelectAnItemFromList<ListItemContact>(
    (item => item.item.id),
    selectedItemKey,
    setSelectedItemKey
  );

  const selectedItem = items.find((item) => item.item.id === selectedItemKey);

  useEffect(() => {

    ServiceApiPageDataContacts.getContacts(pageId).then((contacts) => {

      // Wrap the contacts in the ListItem class
      const contactListItems = ListItemHelper.WrapItems(contacts, true);
      setItems(contactListItems);

      // Set the first item as the selected item
      selectContactFromList(contactListItems);
      //selectAnItem(contactListItems);
    });
  }, [pageId]);

  const handleCreateNewContact = (): void => {

    // Check if we already have one contact that's not saved in the backend
    if (ListItemHelper.IsAnyItemUnsaved(items)) {
      // Select that item
      const unsavedItem = items.find((item) => item.isSavedInTheBackend === false);
      if (unsavedItem) {
        setSelectedItemKey(unsavedItem.item.id);
      }
      return;
    }

    // Create new contact
    const newContact = ContactFactory.createNew(GuidUtil.GenerateNewGuid(), pageId);
    newContact.name = 'Novo Contacto';

    // Create a new ListItem with the new contact
    const newContactListItem = new ListItem(newContact, false);

    // Add the new contact to the list
    setItems([...items, newContactListItem]);

    // Set this item as the selected item
    setSelectedItemKey(newContactListItem.item.id);
  }

  const handleSaveContact = (contact: Contact): void => {

    // Get the item and the index
    const {
      index: itemIndex,
      item: contactListItem
    } = ArrayUtil.findIndexAndItem(items, (item) => item.item.id === contact.id);

    if (contactListItem === undefined) {
      return;
    }

    // Update the backend
    if (contactListItem.isSavedInTheBackend === false) {
      ServiceApiPageDataContacts.createContact(siteId, contact);
    }
    else {
      ServiceApiPageDataContacts.updateContact(siteId, contact);
    }

    // Update this item with the new content
    contactListItem.item = contact;
    contactListItem.isSavedInTheBackend = true;

    // Update the items array
    const newItems = [...items];
    newItems[itemIndex] = contactListItem;
    newItems.sort((a, b) => b.item.displayOrder - a.item.displayOrder);
    setItems(newItems);
  }

  const handleDeleteContact = (contact: Contact): void => {

    const contactListItem = items.find((item) => item.item.id === contact.id);
    if (contactListItem === undefined) {
      return;
    }

    if (contactListItem.isSavedInTheBackend === false) {
      // Remove the item from the list
      const newItems = items.filter((item) => item.item.id !== contact.id);
      setItems(newItems);
      selectContactFromList(newItems);
    }
    else {
      // Update the backend
      ServiceApiPageDataContacts.deleteContact(siteId, contactListItem.item.id);

      // Remove the item from the list
      const newItems = items.filter((item) => item.item.id !== contact.id);
      setItems(newItems);
      selectContactFromList(newItems);
    }
  }

  const handleContactsOrderUpdate = (newItems: ListItemContact[]): void => {
    setItems(newItems);
  }

  const handleContactsOrderFinishUpdate = async (): Promise<void> => {

    // Create a new array with the new display order
    const contactIds = items.map((item) => {
      return item.item.id;
    });

    await ServiceApiPageDataContacts.setDisplayOrder(siteId, pageId, contactIds);
  }

  return (
    <div className="page-contents-editor-contacts-component">
      <ContainerLabeledComponent
        label='Contactos'
        uniqueId={`page-content-contacts-${pageId}`}
      >
        <ListAndItemComponent>
          <div
            className='contacts-side-panel'
          >
            <div>
              <VerticalListComponent>
                <Button
                  variant='outline-primary'
                  style={{ width: "100%" }}
                  onClick={handleCreateNewContact}
                >
                  Adicionar Contacto
                </Button>

                <ItemsListComponent
                  items={items}
                  listName='contacts'
                  getItemKey={(contact) => contact.item.id}
                  getItemLabel={(contact) => contact.item.name || ''}
                  selectedItemKey={selectedItemKey}
                  onSelectedItemChanged={setSelectedItemKey}
                  allowSorting={true}
                  onItemsReordered={handleContactsOrderUpdate}
                  onFinihedSorting={handleContactsOrderFinishUpdate}
                />

              </VerticalListComponent>
            </div>
          </div>

          <div>
            {
              selectedItem && (
                <ContactFormComponent
                  contactToEdit={selectedItem.item}
                  onSaveContact={handleSaveContact}
                  onDeleteContact={handleDeleteContact}
                />
              )
            }
          </div>
        </ListAndItemComponent>
      </ContainerLabeledComponent>
    </div>
  );
}
