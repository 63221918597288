import { Dropdown, DropdownButton } from 'react-bootstrap';

import './adminUserQueriesListItemActions.css';

interface AdminUserQueriesListItemActionsProps {
  onItemMarkAsUnread: () => void;
}

export function AdminUserQueriesListItemActionsComponent({
  onItemMarkAsUnread
}: AdminUserQueriesListItemActionsProps): JSX.Element {
  return (
    <div className="admin-user-queries-list-item-actions-component">
      <DropdownButton
        id="dropdown-actions"
        title="..."
        size='sm'
        variant="light"
      >
        <Dropdown.Item onClick={onItemMarkAsUnread}>
          Marcar mensagem como não lida
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
}
