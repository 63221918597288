import { useMemo } from "react";
import { RenderElementProps } from "slate-react";
import { CustomElement, CustomElementTypesEnum } from "../types";
import { RenderHeadingOneComponent } from "./headingOne/renderHeadingOne";
import { RenderHeadingTwoComponent } from "./headingTwo/renderHeadingTwo";
import { RenderHeadingThreeComponent } from "./headingThree/renderHeadingThree";
import { RenderParagraphComponent } from "./paragraph/renderParagraph";
import { RenderImageComponent } from "./image/renderImage";
import { RenderUrlComponent } from "./url/renderUrl";
import { RenderImageUserComponent } from "./imageUser/renderImageUser";
import { RenderImageUserGalleryComponent } from "./imageUserGallery/renderImageUserGallery";
import { RichTextEditorElementToolbarComponent } from "../toolbars/toolbarElement/richTextEditorElementToolbar";
import { useRichTextEditorOperationsContext } from "../contexts/operationsContext/hooks/useRichTextEditorOperationsContext";
import { RenderYoutubeVideoComponent } from "./youtubeVideo/renderYoutubeVideo";
import { RenderYoutubeShortComponent } from "./youtubeShort/renderYoutubeShort";
import { RenderUserQueryComponent } from "./userQuery/renderUserQuery";

import './render.css';

export function RichTextEditorRender(props: RenderElementProps): JSX.Element {

  const renderContext = useRichTextEditorOperationsContext();

  const typeMapping = useMemo(() => [
    { type: CustomElementTypesEnum.HeadingOne, component: RenderHeadingOneComponent },
    { type: CustomElementTypesEnum.HeadingTwo, component: RenderHeadingTwoComponent },
    { type: CustomElementTypesEnum.HeadingThree, component: RenderHeadingThreeComponent },
    { type: CustomElementTypesEnum.Paragraph, component: RenderParagraphComponent },
    { type: CustomElementTypesEnum.Image, component: RenderImageComponent },
    { type: CustomElementTypesEnum.ImageUser, component: RenderImageUserComponent },
    { type: CustomElementTypesEnum.ImageUserGallery, component: RenderImageUserGalleryComponent },
    { type: CustomElementTypesEnum.Url, component: RenderUrlComponent },
    { type: CustomElementTypesEnum.YoutubeVideo, component: RenderYoutubeVideoComponent },
    { type: CustomElementTypesEnum.YoutubeShort, component: RenderYoutubeShortComponent },
    { type: CustomElementTypesEnum.UserQuery, component: RenderUserQueryComponent },
  ], []);

  const elementType = (props.element as CustomElement).type;
  const RenderComponent = typeMapping.find(mapping => mapping.type === elementType)?.component;

  if (!RenderComponent) {
    console.log(`No render method found for element type ${elementType}`);
    return <p {...props.attributes}>{props.children}</p>;
  }

  return <div className="render">
    <div>
      <RenderComponent
        attributes={props.attributes}
        element={props.element}
      >
        {props.children}
      </RenderComponent>
    </div>

    {renderContext.editing &&
      <div className="render-config">
        <RichTextEditorElementToolbarComponent
          element={props.element}
        />
      </div>
    }
  </div>
}

