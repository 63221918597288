import { useEffect } from 'react';
import { useRichTextEditor } from '../../../../../common-frontend/components/richTextEditor/hooks/useRichTextEditor';
import { RichTextEditor } from '../../../../../common-frontend/components/richTextEditor/richTextEditor';
import { VerticalListComponent } from '../../../../../common-frontend/components/verticalList/verticalList';
import { ContainerLabeledComponent } from '../../../../../common-frontend/components/containerLabeled/containerLabeled';
import { ServiceApiPageDataStatic } from '../../../../../services/api/serviceApiPageDataStatic';
import { useRichTextEditorContentManagerSite } from '../../common/richTextEditorContentManagerSite/useRichTextEditorContentManagerSite';
import { useAutoSave } from '../../../../../common-frontend/hooks/autoSave/autoSave';
import { ServiceApiSites } from '../../../../../services/api/serviceApiSites';
import { PageFeaturesCollect } from '../../pageFeatures/pageFeaturesCollect';
import { DynamicPageContentsEditProps } from '../dynamicPageContentsEditMapping';

export function PageContentsEditorStaticComponent({
  siteId,
  pageId,
  callbackPageContentsChanged
}: DynamicPageContentsEditProps): JSX.Element {

  const editorConfig = useRichTextEditor();
  const contentManagerSite = useRichTextEditorContentManagerSite(siteId, pageId);

  const handleSaveEditorContents = (data: string): boolean => {
    // Save the data
    ServiceApiPageDataStatic.updatePageDataStatic(siteId, pageId, data);

    // Update the features used in this page
    const features = PageFeaturesCollect.getPageFeaturesFromRichTextEditorContent(
      data
    );

    ServiceApiSites.updatePageFeatures(siteId, pageId, features).then(() => {
      callbackPageContentsChanged();
    });

    return true;
  }

  const {
    updateData: updateEditorData
  } = useAutoSave<string>(handleSaveEditorContents);


  useEffect(() => {

    const getStaticPageData = async () => {
      const content = await ServiceApiPageDataStatic.getPageDataStatic(pageId);
      editorConfig.updateInitialValue(content);
    }

    getStaticPageData();
  }, [siteId, pageId]);

  return (
    <div className="page-contents-editor-static-component">
      <ContainerLabeledComponent
        label='Conteúdo'
        uniqueId={`page-content-static-${pageId}`}
      >
        <VerticalListComponent>
          {/* EDITOR */}
          <RichTextEditor
            key={editorConfig.getEditorKey()}
            editable={editorConfig.getEditorEditable()}
            initialValue={editorConfig.getEditorStartupValue()}
            contentManager={contentManagerSite}
            onChange={(newValue) => {
              editorConfig.updateContents(newValue);
              updateEditorData(newValue);
            }}
          />
        </VerticalListComponent>
      </ContainerLabeledComponent>
    </div>
  );
}
