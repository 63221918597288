import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ServiceApiSites } from '../../../../services/api/serviceApiSites';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { Spanner } from '../../../../common-frontend/components/micro/spanner/spanner';

import './dynamicSiteHeader.css';

interface DynamicSiteHeaderProps {
  siteId: string;
  onShowMenu: () => void;
}

export function DynamicSiteHeaderComponent({ siteId, onShowMenu }: DynamicSiteHeaderProps): JSX.Element {

  const [siteName, setSiteName] = useState<string>('');

  useEffect(() => {
    const getSiteName = async () => {
      const siteInfo = await ServiceApiSites.getSiteInfo(siteId);
      if (siteInfo !== undefined) {
        setSiteName(siteInfo.name);
      }
    };
    getSiteName();

  }, [siteId]);

  return (
    <div className="dynamic-site-header-component">
      <Container>
        <HorizontalListComponent>
          <div
            className="side-menu-toogle"
            onClick={onShowMenu}>
            <i className="bi bi-list"></i>
          </div>
          <div>
            {siteName}
          </div>
          <Spanner />
        </HorizontalListComponent>
      </Container>
    </div>
  );
}
