import { useCallback, useState } from 'react';
import { GuidUtil } from '../../../../../common/util/guid/guid';
import { SitePageType } from '../../../../../common-app/sites/enums/sitePageType';
import { SiteEditorHeaderComponent } from '../siteEditorHeader/siteEditorHeader';
import { SiteEditorMainAreaComponent } from '../siteEditorMainArea/siteEditorMainArea';
import { SiteEditorSidePanelComponent } from '../siteEditorSidePanel/siteEditorSidePanel';
import { ServiceApiSites } from '../../../../../services/api/serviceApiSites';
import { SitePageTypesInfo } from '../../../../../common-frontend/features/sites/sitePageTypesInfo';
import { useForceRefresh } from '../../../../../common-frontend/hooks/refresh/forceRefresh';

import './siteEditor.css';

interface SiteEditorProps {
  siteId: string;
  site: string;
}

export function SiteEditorComponent({ siteId, site }: SiteEditorProps): JSX.Element {

  const [selectedItemId, setSelectedItem] = useState(siteId);
  const [siteEditorSidePanelDataVersion, updateSiteEditorSidePanelDataVersion] = useForceRefresh();

  const handleAddNewPage = async (newPageType: SitePageType) => {

    // Determine the page we're usig as a positional reference for the new page
    const anchorPageId = selectedItemId !== siteId ? selectedItemId : undefined;

    const pageName = SitePageTypesInfo.GetPageTypeName(newPageType);

    const newPageId = GuidUtil.GenerateNewGuid();
    const result = await ServiceApiSites.addSitePage(siteId, newPageId, newPageType, pageName, anchorPageId);

    if (result) {
      setSelectedItem(newPageId);
      updateSiteEditorSidePanelDataVersion();
    }
  }

  const callbackPagesChanged = useCallback((): void => {
    updateSiteEditorSidePanelDataVersion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId, siteEditorSidePanelDataVersion]);

  return (
    <div className="site-editor-component">

      {/* Header */}
      <SiteEditorHeaderComponent />

      {/* Work Area */}
      <div className='work-area'>

        {/* Side Panel */}
        <SiteEditorSidePanelComponent
          dataVersion={siteEditorSidePanelDataVersion}
          site={site}
          siteId={siteId}
          selectedItemId={selectedItemId}
          setSelectedItem={setSelectedItem}
          onAddNewPage={(newPageType) => handleAddNewPage(newPageType)}
          callbackPagesChanged={callbackPagesChanged}
        />

        {/* Main Area */}
        <div className='main-area'>
          <SiteEditorMainAreaComponent
            siteId={siteId}
            selectedItemId={selectedItemId}
            callbackPagesChanged={callbackPagesChanged}
          />
        </div>
      </div>
    </div>
  );
}
