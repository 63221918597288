import { TypeRebuilding } from "../../common/util/types/typeRebuilding";
import { CreateBlogPostRequestBody, CreateBlogPostRouteDef } from "../../common-app/blogs/actions/createBlogPost";
import { DeleteBlogPostRouteDef } from "../../common-app/blogs/actions/deleteBlogPost";
import { GetBlogPostResponse, GetBlogPostRouteDef } from "../../common-app/blogs/actions/getBlogPost";
import {
  GetBlogPostAdminCountResponse,
  GetBlogPostAdminCountRouteDef
} from "../../common-app/blogs/actions/getBlogPostAdminCount";
import {
  GetBlogPostAdminListResponse,
  GetBlogPostAdminListRouteDef
} from "../../common-app/blogs/actions/getBlogPostAdminList";
import {
  UpdateBlogPostRequestBody,
  UpdateBlogPostRouteDef
} from "../../common-app/blogs/actions/updateBlogPost";
import {
  GetBlogPostListResponse,
  GetBlogPostListRouteDef
} from "../../common-app/blogs/actions/getBlogPostList";
import {
  GetBlogPostCountResponse,
  GetBlogPostCountRouteDef
} from "../../common-app/blogs/actions/getBlogPostCount";
import {
  GetAdjacentBlogPostsResponse,
  GetAdjacentBlogPostsRouteDef
} from "../../common-app/blogs/actions/getAdjacentBlogPosts";
import { BlogPost } from "../../common-app/blogs/models/blogPost";
import { BlogPostAdmin } from "../../common-app/blogs/models/blogPostAdmin";
import { BlogPostAdminUpdate } from "../../common-app/blogs/models/blogPostAdminUpdate";
import { ApiService } from "../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import { RequestHeaderHelper } from "../requestHeaderData/requestHeaderHelper";

export class ServiceApiPageDataBlogs extends ApiService {

  static createBlogPost(siteId: string, blogPost: BlogPostAdminUpdate): Promise<boolean> {

    const routeDef = new CreateBlogPostRouteDef();

    const body = new CreateBlogPostRequestBody(blogPost);
    const requestOptions = this.getRequestOptions(routeDef, body);
    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static deleteBlogPost(siteId: string, blogPostId: string): Promise<boolean> {

    const routeDef = new DeleteBlogPostRouteDef();

    const requestOptions = this.getDeleteRequestOptions();
    return this.DoRequest(
      routeDef.route(blogPostId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async updateBlogPost(siteId: string, blogPost: BlogPostAdminUpdate): Promise<boolean> {

    const routeDef = new UpdateBlogPostRouteDef();

    const body = new UpdateBlogPostRequestBody(
      blogPost.id,
      blogPost.title,
      blogPost.subTitle,
      blogPost.content,
      blogPost.visible
    );
    const requestOptions = this.getRequestOptions(routeDef, body);
    return this.DoRequest(
      routeDef.route(blogPost.id),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async getBlogPost(pageId: string, postId: string): Promise<BlogPost | undefined> {

    const routeDef = new GetBlogPostRouteDef();

    const requestOptions = this.getGetRequestOptions();
    const response = await this.DoRequestWithResponse<GetBlogPostResponse>(
      routeDef.route(pageId, postId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (!response) {
      return undefined;
    }

    return TypeRebuilding.rebuildObject(
      response.post,
      () => new BlogPost()
    );
  }

  static async getBlogPosts(pageId: string, pageSize: number, page: number): Promise<BlogPost[]> {

    const routeDef = new GetBlogPostListRouteDef();

    const requestOptions = this.getGetRequestOptions();
    const response = await this.DoRequestWithResponse<GetBlogPostListResponse>(
      routeDef.route(pageId, pageSize, page),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (!response) {
      return [];
    }

    return TypeRebuilding.rebuildObjectArray(
      response.blogPosts,
      () => new BlogPost()
    );
  }

  static async getBlogPostCount(pageId: string): Promise<number> {

    const routeDef = new GetBlogPostCountRouteDef();

    const requestOptions = this.getGetRequestOptions();
    const response = await this.DoRequestWithResponse<GetBlogPostCountResponse>(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (!response) {
      return 0;
    }

    return response.count;
  }

  static async getBlogPostAdminCount(siteId: string, pageId: string): Promise<number> {

    const routeDef = new GetBlogPostAdminCountRouteDef();

    const requestOptions = this.getGetRequestOptions();
    const response = await this.DoRequestWithResponse<GetBlogPostAdminCountResponse>(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );

    if (!response) {
      return 0;
    }

    return response.count;
  }

  static async getBlogPostAdminList(siteId: string, pageId: string, pageSize: number, page: number): Promise<BlogPostAdmin[]> {

    const routeDef = new GetBlogPostAdminListRouteDef();

    const requestOptions = this.getGetRequestOptions();
    const response = await this.DoRequestWithResponse<GetBlogPostAdminListResponse>(
      routeDef.route(pageId, pageSize, page),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );

    if (!response) {
      return [];
    }

    return TypeRebuilding.rebuildObjectArray(
      response.blogPosts,
      () => new BlogPostAdmin()
    );
  }

  static async getAdjacentBlogPosts(pageId: string, postId: string): Promise<{ previous: BlogPost | undefined, next: BlogPost | undefined }> {

    const routeDef = new GetAdjacentBlogPostsRouteDef();

    const requestOptions = this.getGetRequestOptions();
    const response = await this.DoRequestWithResponse<GetAdjacentBlogPostsResponse>(
      routeDef.route(pageId, postId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (!response) {
      return {
        previous: undefined,
        next: undefined
      }
    }

    if (response.older) {
      response.older = TypeRebuilding.rebuildObject(response.older, () => new BlogPost());
    }

    if (response.newer) {
      response.newer = TypeRebuilding.rebuildObject(response.newer, () => new BlogPost());
    }

    return {
      previous: response.older,
      next: response.newer
    }
  }
}