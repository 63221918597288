import { SitePageType } from "../../../../common-app/sites/enums/sitePageType";
import { PageContentsEditorBlogComponent } from "./pageContentsEditorBlog/pageContentsEditorBlog";
import { PageContentsEditorContactsComponent } from "./pageContentsEditorContacts/pageContentsEditorContacts";
import { PageContentsEditorStaticComponent } from "./pageContentsEditorStatic/pageContentsEditorStatic";

export interface DynamicPageContentsEditProps {
  siteId: string;
  pageId: string;
  callbackPageContentsChanged: () => void;
}

export class DynamicPageContentsEditMapping {

  public static getPageContentsEditComponent(pageType: SitePageType): React.ComponentType<DynamicPageContentsEditProps> | null {
    return this.pageContentsEditComponentMap[pageType];
  }

  private static pageContentsEditComponentMap: Record<SitePageType, React.ComponentType<DynamicPageContentsEditProps> | null> = {
    [SitePageType.Static]: PageContentsEditorStaticComponent,
    [SitePageType.Blog]: PageContentsEditorBlogComponent,
    [SitePageType.Contacts]: PageContentsEditorContactsComponent,
    [SitePageType.Unknown]: null
  };
}