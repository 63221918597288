import { useState } from "react";
import { Button } from "react-bootstrap";
import { SiteHomePageSelectModalComponent } from "../../../common/siteHomePageSelectModal/siteHomePageSelectModal";

interface HomePageSelectionButtonProps {
  siteId: string;
  homePageId: string;
  onHomePageSelected: (homePageId: string) => void;
}

export function HomePageSelectionButtonComponent({
  siteId,
  homePageId,
  onHomePageSelected
}: HomePageSelectionButtonProps): JSX.Element {

  const [showDialog, setShowDialog] = useState(false);

  const handleSelectHomePage = (homePageId: string) => {
    setShowDialog(false);
    onHomePageSelected(homePageId);
  }

  return <>
    <Button size="sm"
      onClick={() => { setShowDialog(true) }}
    >
      Escolher
    </Button>

    <SiteHomePageSelectModalComponent
      onClose={() => { setShowDialog(false) }}
      onSelectHomePage={handleSelectHomePage}
      siteId={siteId}
      pageId={homePageId}
      show={showDialog} />
  </>
}