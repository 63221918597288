import { TypeRebuilding } from "../../common/util/types/typeRebuilding";
import { ApiService } from "../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import {
  CreateContactRequestBody,
  CreateContactRouteDef
} from "../../common-app/pagesContacts/actions/createContact";
import {
  DeleteContactRouteDef
} from "../../common-app/pagesContacts/actions/deleteContact";
import {
  GetContactsResponse,
  GetContactsRouteDef
} from "../../common-app/pagesContacts/actions/getContacts";
import {
  SetDisplayOrderRequestBody,
  SetDisplayOrderRouteDef
} from "../../common-app/pagesContacts/actions/setDisplayOrder";
import {
  UpdateContactRequestBody,
  UpdateContactRouteDef
} from "../../common-app/pagesContacts/actions/updateContact";
import {
  Contact
} from "../../common-app/pagesContacts/models/contact";
import { RequestHeaderHelper } from "../requestHeaderData/requestHeaderHelper";

export class ServiceApiPageDataContacts extends ApiService {

  static createContact(siteId: string, contact: Contact): Promise<boolean> {
    const routeDef = new CreateContactRouteDef();
    const body = new CreateContactRequestBody(contact);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static deleteContact(siteId: string, contactId: string): Promise<boolean> {
    const routeDef = new DeleteContactRouteDef();
    const requestOptions = this.getDeleteRequestOptions();

    return this.DoRequest(
      routeDef.route(contactId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static updateContact(siteId: string, contact: Contact): Promise<boolean> {
    const routeDef = new UpdateContactRouteDef();
    const body = new UpdateContactRequestBody(contact);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(contact.id),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async getContacts(pageId: string): Promise<Contact[]> {
    const routeDef = new GetContactsRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetContactsResponse>(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    // Check the response
    if (response === undefined) {
      return [];
    }

    return TypeRebuilding.rebuildObjectArray(
      response.contacts,
      () => new Contact()
    );
  }

  static async setDisplayOrder(siteId: string, pageId: string, contactIds: string[]): Promise<boolean> {
    const routeDef = new SetDisplayOrderRouteDef();
    const body = new SetDisplayOrderRequestBody(pageId, contactIds);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async getMainContact(): Promise<Contact | undefined> {
    /*
    const contacts = await this.getContacts();
    if (contacts.length === 0) {
      return undefined;
    }

    return contacts[0];
    */

    // Fix me later!
    return undefined;
  }

}