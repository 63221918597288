import { CustomElementTypesEnum } from "../../types";

export class ElementConfiguration {
  public static isEditable(elementType: CustomElementTypesEnum): boolean {
    switch (elementType) {
      case CustomElementTypesEnum.Image:
      case CustomElementTypesEnum.ImageUser:
      case CustomElementTypesEnum.ImageUserGallery:
      case CustomElementTypesEnum.Url:
      case CustomElementTypesEnum.YoutubeVideo:
      case CustomElementTypesEnum.YoutubeShort:
      case CustomElementTypesEnum.UserQuery:
        return true;
      case CustomElementTypesEnum.Paragraph:
      case CustomElementTypesEnum.HeadingOne:
      case CustomElementTypesEnum.HeadingTwo:
      case CustomElementTypesEnum.HeadingThree:
        return false;
    }
  }
}
