import { useEffect, useState } from "react";
import { ServiceApiSites } from "../../../../../../services/api/serviceApiSites";
import { PageNameComponent } from "../../../common/pageName/pageName";

import './pageNameDisplay.css';

interface PageNameDisplayProps {
  siteId: string;
  pageId: string;
}

export function PageNameDisplayComponent({
  siteId,
  pageId
}: PageNameDisplayProps): JSX.Element {

  const [pageDataName, setPageDataName] = useState<string>("");

  useEffect(() => {

    const loadPageInfo = async () => {
      const pageName = await ServiceApiSites.getSitePageName(siteId, pageId);

      if (pageName) {
        setPageDataName(pageName);
      }
    }

    setPageDataName("");
    loadPageInfo();

  }, [siteId, pageId]);

  return <div className="page-name-display-component">
    <PageNameComponent name={pageDataName}></PageNameComponent>
  </div>
}