import { useEffect, useState } from "react";
import { CustomElementImageUser } from "../../types";
import { ServiceApiUserFiles } from "../../../../../services/api/serviceApiUserFiles";
import { ImageGalleryComponent } from "../../../imageGallery/imageGallery";
import { RenderComponentProps } from "../renderComponentProps";
import { ImageGalleryItem } from "../../../imageGallery/imageGalleryItem";
import { useRichTextEditorDataContext } from "../../contexts/dataContext/hooks/useRichTextEditorDataContext";

import './renderImageUser.css';

export function RenderImageUserComponent({
  element,
  attributes,
  children }: RenderComponentProps): JSX.Element {

  const [galleryImage, setGalleryImage] = useState<ImageGalleryItem>();
  const dataContext = useRichTextEditorDataContext();

  useEffect(() => {

    const elementImageUser = element as CustomElementImageUser;

    const fetchImageData = async () => {

      const userFileImages = await ServiceApiUserFiles.getUserImagesInfo(dataContext.siteId, [elementImageUser.image.imageId]);

      if (userFileImages.length === 0) {
        console.error('Failed to fetch image info', elementImageUser.image.imageId);
        return;
      }

      setGalleryImage({
        id: userFileImages[0].id,
        url: dataContext.backendServerAddress + userFileImages[0].publicUrl,
        urlthumbnail: dataContext.backendServerAddress + userFileImages[0].publicUrlThumbnail,
        altText: userFileImages[0].originalFileName,
        caption: elementImageUser.image.caption
      });
    }

    fetchImageData();

  }, [element]);

  return (
    <div className="editor-user-image-node-parent">
      <div
        {...attributes}
        contentEditable={false}
        className="editor-user-image-node">

        {galleryImage &&
          <ImageGalleryComponent
            images={[galleryImage]}
          />
        }

        {children}
      </div>
    </div>
  )
}


