import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { ArrayUtil } from '../../../common/util/array/arrayUtil';
import { ImageCaptionComponent } from '../imageCaption/imageCaption';
import { ImageGalleryItem } from './imageGalleryItem';

import './imageGallery.css';

interface ImageGalleryComponentProps {
  images: ImageGalleryItem[];
}

export function ImageGalleryComponent({ images }: ImageGalleryComponentProps): JSX.Element {

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);

  const selectedImage = images[selectedImageIndex];

  useEffect(() => {

    if (thumbnailContainerRef.current) {

      const thumbnailElement = thumbnailContainerRef.current.children[selectedImageIndex] as HTMLDivElement;
      if (thumbnailElement) {
        thumbnailContainerRef.current.scrollTo({
          left: thumbnailElement.offsetLeft - (
            thumbnailContainerRef.current.offsetWidth / 2 +
            thumbnailElement.offsetWidth / 2
          ),
          behavior: 'smooth',
        });
      }
    }
  }, [selectedImageIndex, images]);

  if (images.length === 0) {
    return <></>;
  }

  return (
    <div className="image-gallery-component">

      <div className='active-item'
        onClick={() => setSelectedImageIndex(ArrayUtil.nextIndex(selectedImageIndex, images))}
      >
        <img
          src={selectedImage.url}
          alt={selectedImage.altText}
          draggable={false}
        />

        <ImageCaptionComponent
          caption={selectedImage.caption}
        />

      </div>

      {images.length > 1 &&

        <div
          className='thumbnails'
          ref={thumbnailContainerRef}
        >
          {images.map((image, index) => (
            <div
              key={index}
              onClick={() => setSelectedImageIndex(index)}
              className={classNames(
                'thumbnail-item',
                { 'thumbnail-item-selected': selectedImage.url === image.url })}
            >
              <img
                src={image.urlthumbnail}
                alt={image.altText}
                draggable={false}
              />

              {/* Hidden image used for preloading the full-resolution image for 
                improved user experience on slow connections */}
              <img
                src={image.url}
                alt={image.altText}
                style={{ display: 'none' }}
              />
            </div>
          ))}
        </div>
      }
    </div>
  );
};

