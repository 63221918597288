import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { AddSitePageDialogComponent } from '../addSitePageDialog/addSitePageDialog';
import { SitePageType } from '../../../../../../common-app/sites/enums/sitePageType';

import './addSitePageButton.css';

interface AddSitePageButtonProps {
  onAddNewPage: (pageType: SitePageType) => void;
}

export function AddSitePageButtonComponent({
  onAddNewPage
}: AddSitePageButtonProps
): JSX.Element {

  const [isDialogVisible, setDialogVisible] = useState(false);

  const handleSitePageTypeSelected = (pageType: SitePageType) => {
    hideDialog();
    onAddNewPage(pageType);
  }

  const handleDismiss = () => {
    hideDialog();
  }

  const hideDialog = () => {
    setDialogVisible(false);
  }

  const showDialog = () => {
    setDialogVisible(true);
  }

  return (
    <div
      className="add-site-page-button-component">

      <Button
        className='add-site-page-button'
        variant='outline-primary'
        onClick={showDialog}
      >
        Adicionar Página
      </Button>

      {isDialogVisible &&
        <AddSitePageDialogComponent
          show={isDialogVisible}
          onPageSelected={handleSitePageTypeSelected}
          onDismiss={handleDismiss}
        />
      }
    </div>
  );
}
