import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { GuidUtil } from '../../../common/util/guid/guid';
import { UserQueryCreationFactory } from '../../../common-app/userQueries/models/factories/userQueryCreationFactory';
import { FormEditComponent } from '../form/formEdit/formEdit';
import { VerticalListComponent } from '../verticalList/verticalList';
import { FormTextAreaComponent } from '../form/formTextArea/formTextArea';
import { ServiceApiUserQueries } from '../../../services/api/serviceApiUserQueries';

interface UserQueryProps {
  siteId: string;
  pageId: string;
  userQueryId: string;
}

export function UserQueryComponent({ siteId, pageId, userQueryId }: UserQueryProps): JSX.Element {

  const [contact, setContact] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const validDataToSubmit = (contact !== "") || (subject !== "") || (message !== "");
  const submitEnabled = !submitted && validDataToSubmit;

  const handleSubmit = () => {
    if (submitEnabled === false) {
      return;
    }

    const userQuery = UserQueryCreationFactory.create(
      siteId,
      pageId,
      userQueryId,
      GuidUtil.GenerateNewGuid(),
      contact,
      subject,
      message);

    ServiceApiUserQueries.createUserQuery(userQuery);

    setSubmitted(true);
  }

  return (
    <VerticalListComponent>
      <div>
        O seu telefone ou endereço de email
        <FormEditComponent
          type="text"
          readonly={submitted}
          value={contact}
          onChange={setContact} />
      </div>

      <div>
        Assunto
        <FormEditComponent
          type="text"
          readonly={submitted}
          value={subject}
          onChange={setSubject} />
      </div>

      <div>
        Mensagem
        <FormTextAreaComponent
          lines={5}
          readonly={submitted}
          value={message}
          onChange={setMessage} />
      </div>

      <Button
        onClick={handleSubmit}
        disabled={!submitEnabled}
      >
        {!submitted ? "Enviar" : "Obrigado pela sua mensagem. Entraremos em contacto consigo o mais rápido possível."}
      </Button>
    </VerticalListComponent>
  );
}
