import Form from 'react-bootstrap/Form';

interface FormNumberProps {
    value: string | undefined;
    onChange: (value: string) => void;
}

export function FormNumberComponent({
    value,
    onChange
}: FormNumberProps): JSX.Element {
    return (
        <Form.Control
            type={"text"}
            value={value}
            onChange={(e) => { onChange(e.target.value); }}
        />
    );
}
