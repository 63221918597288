import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createEditor, Descendant, Editor, Path, Transforms, Element, Text } from "slate";
import { Editable, ReactEditor, RenderLeafProps, Slate, withReact } from "slate-react";
import { VerticalListComponent } from "../verticalList/verticalList";
import { EditorKeyboard } from "./richTextEditorKeyboardEvents";
import { RichTextEditorToolbarComponent } from "./toolbars/mainToolbar/richTextEditorToolbar";
import { RichTextEditorContentManager } from "./richTextEditorContentManager";
import { RichTextEditorRender } from "./rendering/render";
import { RichTextEditorOperationsProvider } from "./contexts/operationsContext/richTextEditorOperationsProvider";
import { CustomElement, CustomElementImageUserGalleryVersion, CustomElementImageUserVersion, CustomElementParagraphVersion, CustomElementTypesEnum, CustomElementUrl, CustomElementUrlVersion, CustomElementYoutubeShort, CustomElementYoutubeVideo, CustomElementYoutubeVideoVersion } from "./types";
import { ElementEditComponent } from "./elements/elementEdit/elementEdit";
import { ElementCreationComponent } from "./elements/elementCreation/elementCreation";
import { RichTextEditorDataProvider } from "./contexts/dataContext/richTextEditorDataProvider";

import './richTextEditor.css';

type RichTextEditorProps = {
  initialValue: string;
  onChange: (newValue: string) => void;
  editable: boolean;
  contentManager: RichTextEditorContentManager;
};

export function RichTextEditor({
  initialValue,
  onChange,
  editable,
  contentManager
}: RichTextEditorProps): JSX.Element {

  const [initialValueParsed, setInitialValueParsed] = useState<Descendant[] | undefined>();

  const [elementBeingConfigured, setElementBeingConfigured] = useState<CustomElement | undefined>();
  const [showConfigElementModal, setShowConfigElementModal] = useState(false);

  const [elementBeingCreated, setElementBeingCreated] = useState<CustomElementTypesEnum | undefined>();

  useEffect(() => {

    if (initialValue && initialValue.length > 4) {
      try {
        const parsed = JSON.parse(initialValue) as Descendant[];
        setInitialValueParsed(parsed);
      } catch (e) {
        console.error('Failed to parse initial value', e);
      }
    }
    else {
      const descentant: Descendant = {
        type: CustomElementTypesEnum.Paragraph,
        version: CustomElementParagraphVersion,
        children: [{ text: '' }],
      }

      setInitialValueParsed([descentant]);
    }
  }, [initialValue]);

  useEffect(() => {
    if (showConfigElementModal === false) {
      setElementBeingConfigured(undefined);
    }
  }, [showConfigElementModal]);

  const editor = useMemo(() => {
    const ed = withReact(createEditor());
    const { isVoid } = ed;
    ed.isVoid = element => {

      if (
        element.type === CustomElementTypesEnum.Image ||
        element.type === CustomElementTypesEnum.ImageUser ||
        element.type === CustomElementTypesEnum.ImageUserGallery ||
        element.type === CustomElementTypesEnum.YoutubeVideo ||
        element.type === CustomElementTypesEnum.YoutubeShort) {
        return true;
      }
      return isVoid(element);
    };

    return ed;
  }, [])

  const renderLeaf = useCallback((props: RenderLeafProps) => {
    let { children, leaf, attributes } = props;

    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }

    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.underline) {
      children = <u>{children}</u>;
    }

    if (leaf.strikethrough) {
      children = <span style={{ textDecoration: "line-through" }}>{children}</span>;
    }

    return <span {...attributes}>{children}</span>;
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      EditorKeyboard.handleImageRelatedEvents(editor, event);
    },
    [editor]
  );

  const handleDeleteElement = (element: CustomElement) => {

    // Delete the selected element
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });

    // Handle the case of deleting the last remaining element
    if (editor.children.length === 0) {

      // Insert a new paragraph
      const newParagraph: CustomElement = {
        type: CustomElementTypesEnum.Paragraph,
        version: CustomElementParagraphVersion,
        children: [{ text: '' }]
      };

      // Add the new paragraph at the end of the document
      Transforms.insertNodes(editor, newParagraph);
    }
  }

  const handleInsertEmptyElement = (element: CustomElement, position: "before" | "after") => {
    // Find the path of the current element in the editor
    const path = ReactEditor.findPath(editor, element);

    // Create a new empty paragraph element
    const newParagraph: CustomElement = {
      type: CustomElementTypesEnum.Paragraph,
      version: CustomElementParagraphVersion,
      children: [{ text: "" }]
    };

    // Determine the new path for insertion
    const newPath = position === "before" ? path : Path.next(path);

    // Insert the new paragraph at the determined path
    Transforms.insertNodes(editor, newParagraph, { at: newPath });

    // Set the selection to the newly inserted paragraph
    Transforms.select(editor, newPath);
  }

  const handleConfigureElement = (element: CustomElement) => {

    setElementBeingConfigured(element);
    setShowConfigElementModal(true);
  }

  const handleUpdatedElement = (element: CustomElement) => {

    if (elementBeingConfigured === undefined) {
      return;
    }

    // Replace te element being configured with the updated element

    const path = ReactEditor.findPath(editor, elementBeingConfigured);
    Transforms.removeNodes(editor, { at: path });
    Transforms.insertNodes(editor, element, { at: path });

    setElementBeingConfigured(element);
  }

  const insertEmptyParagraph = () => {

    const paragraphNode: CustomElement = {
      type: CustomElementTypesEnum.Paragraph,
      version: CustomElementParagraphVersion,
      children: [{ text: '' }],
    };

    Transforms.insertNodes(editor, paragraphNode);
  }

  const checkAndDeleteEmptyParagraph = () => {
    const element = Editor.above(editor, {
      match: n => Element.isElement(n),
    });

    if (element) {
      const elementData = element[0] as CustomElement;

      if (elementData.type === CustomElementTypesEnum.Paragraph &&
        elementData.children.length === 1 &&
        (elementData.children[0] as Text).text === "") {

        // If the paragraph is empty, we can delete this node, so it's replaced
        Transforms.delete(editor, { at: ReactEditor.findPath(editor, elementData) });
      }
    }
  }

  const handleInsertUserImages = (images: string[]) => {

    if (images.length === 0) {
      return;
    }

    let newNode: CustomElement | undefined = undefined;

    if (images.length === 1) {
      const firstImageId = images[0];
      newNode = {
        type: CustomElementTypesEnum.ImageUser,
        version: CustomElementImageUserVersion,
        image: { imageId: firstImageId, caption: '' },
        children: [{ text: '' }],
      }
    }
    else {
      newNode = {
        type: CustomElementTypesEnum.ImageUserGallery,
        version: CustomElementImageUserGalleryVersion,
        images: images.map(imageId => ({ imageId, caption: '' })),
        caption: '',
        children: [{ text: '' }],
      };
    }

    Transforms.insertNodes(editor, newNode);

    insertEmptyParagraph();
  }

  const handleInsertElement = (element: CustomElement) => {

    checkAndDeleteEmptyParagraph();

    Transforms.insertNodes(editor, element);
  }

  const handleInsertUrl = () => {
    setElementBeingCreated(CustomElementTypesEnum.Url);
  }

  const handleInsertYoutubeVideo = () => {
    setElementBeingCreated(CustomElementTypesEnum.YoutubeVideo);
  }

  const handleInsertYoutubeShort = () => {
    setElementBeingCreated(CustomElementTypesEnum.YoutubeShort);
  }

  const handleInsertUserQuery = () => {
    setElementBeingCreated(CustomElementTypesEnum.UserQuery);
  }

  return (
    <div
      className="rich-text-editor-componenent">

      <RichTextEditorDataProvider
        siteId={contentManager.getSiteIdFunction()}
        pageId={contentManager.getPageIdFunction()}
        backendServerAddress={contentManager.getBackendServerEndpointFunction()}
        getUserImagesInfo={contentManager.getImagesListFunction}
        insertUserQuery={contentManager.insertUserQueryFunction}
      >
        <RichTextEditorOperationsProvider
          editing={editable}
          deleteElementFunction={handleDeleteElement}
          insertEmptyElementFunction={handleInsertEmptyElement}
          configureElementFunction={handleConfigureElement}
        >
          <VerticalListComponent>
            {editable &&
              <RichTextEditorToolbarComponent
                editor={editor}
                contentManager={contentManager}
                handleInsertUserImages={handleInsertUserImages}
                handleInsertUrl={handleInsertUrl}
                handleInsertYoutubeVideo={handleInsertYoutubeVideo}
                handleInsertYoutubeShort={handleInsertYoutubeShort}
                handleInsertUserQuery={handleInsertUserQuery}
              />
            }
            {initialValueParsed &&
              <div className={classNames({ 'height-constrained': editable === true })}
              >
                <Slate
                  editor={editor}
                  initialValue={initialValueParsed}
                  onChange={(newValue: Descendant[]) => {
                    onChange(JSON.stringify(newValue));

                  }}>
                  <Editable
                    readOnly={editable === false}
                    className={classNames("editor", { "editable": editable })}
                    renderElement={RichTextEditorRender}
                    renderLeaf={renderLeaf}
                    onKeyDown={handleKeyDown}
                  />
                </Slate>
              </div>
            }
          </VerticalListComponent>

          {elementBeingConfigured &&
            <ElementEditComponent
              contentManager={contentManager}
              element={elementBeingConfigured}
              show={showConfigElementModal}
              onConfirm={handleUpdatedElement}
              onDismiss={() => setShowConfigElementModal(false)}
            />
          }

          {elementBeingCreated !== undefined &&
            <ElementCreationComponent
              contentManager={contentManager}
              elementType={elementBeingCreated}
              show={elementBeingCreated !== undefined}
              onConfirm={(element) => {
                handleInsertElement(element);
                setElementBeingCreated(undefined)
              }}
              onDismiss={() => {
                setElementBeingCreated(undefined)
              }}
            />
          }

        </RichTextEditorOperationsProvider>
      </RichTextEditorDataProvider>
    </div >
  );
}
