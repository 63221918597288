
import { SitePageFeature } from "../../../../common-app/sites/enums/sitePageFeature";
import { PageFeatureUserQueryComponent } from "./userQuery/pageFeatureUserQuery";

export interface DynamicPageFeatureComponentProps {
  siteId: string;
  pageId: string;
}

export class DynamicPageFeatureMapping {

  public static getPageFeatureComponent(pageFeature: SitePageFeature): React.ComponentType<DynamicPageFeatureComponentProps> | null {
    return this.pageFeatureComponentMap[pageFeature];
  }

  private static pageFeatureComponentMap: Record<SitePageFeature, React.ComponentType<DynamicPageFeatureComponentProps> | null> = {
    [SitePageFeature.UserQuery]: PageFeatureUserQueryComponent,
    [SitePageFeature.Unknown]: null
  };
}