import { ContactsComponent } from './contacts/contacts';
import { DynamicPageContactsProvider } from './provider/dynamicPageContactsProvider';

import './dynamicPageContacts.css'

interface DynamicPageContactsProps {
  siteId: string;
  pageId: string;
  dataProvider: DynamicPageContactsProvider;
}

export function DynamicPageContactsComponent({
  siteId,
  pageId,
  dataProvider
}: DynamicPageContactsProps): JSX.Element {

  return <ContactsComponent
    pageId={pageId}
    contactsProvider={dataProvider}
  />
}