import { Button } from 'react-bootstrap';
import { SitesUtil } from '../../../../../common/sites/sitesUtil';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HorizontalListComponent } from '../../../../../common-frontend/components/horizontalList/horizontalList';
import { Spanner } from '../../../../../common-frontend/components/micro/spanner/spanner';
import { useUserSessionContext } from '../../../../contexts/session/hooks/useUserSessionContext';
import { LogoComponent } from '../../../../../common-frontend/components/logo/logo';

import './siteEditorHeader.css';

export function SiteEditorHeaderComponent(): JSX.Element {

    const location = useLocation();
    const navigate = useNavigate();
    const userSessionContext = useUserSessionContext();

    const handleCloseEditor = () => {

        const siteHomeLocation = SitesUtil.getHomePageAddress(location.pathname);
        navigate(siteHomeLocation);
    }

    return (
        <div className="site-editor-header-component">
            <HorizontalListComponent>
                <Link to={'/'} >
                    <div className='logo-holder'>
                        <LogoComponent />
                    </div>
                </Link>
                <Spanner />

                <div className='buttons-holder'>
                    {
                        userSessionContext.userSessionId &&
                        <Link to={"/user-sites"}>
                            <Button
                                variant="outline-primary"
                            >
                                Lista de sites
                            </Button>
                        </Link>
                    }

                    {
                        userSessionContext.userSessionId === '' &&
                        <Link to={"/signin"}>
                            <Button
                                variant="outline-primary"
                            >
                                Iniciar sessão
                            </Button>
                        </Link>
                    }

                    <Button
                        variant="primary"
                        onClick={handleCloseEditor}
                    >
                        Ver site
                    </Button>

                </div>

            </HorizontalListComponent>
        </div>
    );
}
