import { useEffect, useState } from "react";
import { useRichTextEditor } from "../../richTextEditor/hooks/useRichTextEditor";
import { RichTextEditor } from "../../richTextEditor/richTextEditor";
import { useRichTextEditorContentManagerSite } from "../../../../components/sites/editor/common/richTextEditorContentManagerSite/useRichTextEditorContentManagerSite";
import { BlogPost } from "../../../../common-app/blogs/models/blogPost";
import { NullFnc } from "../../../library/events/eventUtil";
import { DateUtil } from "../../../../common/util/date/dateUtil";
import { BlogPostAdjacentNavigationComponent } from "./blogPostAdjacentNavigation/blogPostAdjacentNavigation";
import { DynamicPageBlogProvider } from "./provider/dynamicPageBlogProvider";

import './dynamicPageBlogPost.css';

interface DynamicPageBlogPostProps {
  dataProvider: DynamicPageBlogProvider;
  siteId: string;
  pageId: string;
  postId: string;
}

export function DynamicPageBlogPostComponent({ dataProvider, siteId, pageId, postId }: DynamicPageBlogPostProps): JSX.Element {

  const [postToRender, setPostToRender] = useState<BlogPost | undefined>();

  const [previousPost, setPreviousPost] = useState<BlogPost | undefined>();
  const [nextPost, setNextPost] = useState<BlogPost | undefined>();

  const editorConfig = useRichTextEditor(true);
  const contentManagerSite = useRichTextEditorContentManagerSite(siteId, pageId);

  useEffect(() => {

    const loadPostInfo = async () => {

      const post = await dataProvider.getBlogPost(pageId, postId);
      setPostToRender(post);

      if (post) {
        editorConfig.updateReadOnly(true);
        editorConfig.updateInitialValue(post.content ? post.content : '');
      }
    }

    const loadAdjacentPosts = async () => {

      const adjacentPosts = await dataProvider.getAdjacentBlogPosts(pageId, postId);

      if (adjacentPosts) {
        setPreviousPost(adjacentPosts.previous);
        setNextPost(adjacentPosts.next);
      }
    }

    // Load the post from the server
    loadPostInfo();

    // Load the adjacent posts from the server
    loadAdjacentPosts();

  }, [siteId, pageId, postId]);


  return (
    <div className="dynamic-page-blog-post-component">
      {postToRender &&
        <div>
          <div className="title-holder">
            <div className="title">
              {postToRender.title}
            </div>

            {
              postToRender.subTitle && postToRender.subTitle.length > 0 &&
              (
                <div className="subtitle">
                  {postToRender.subTitle}
                </div>
              )
            }
            <div className="date">
              {DateUtil.formatDateAsHumanReadable(postToRender.creationDate)}
            </div>

          </div>
          <div className="content-holder">
            <RichTextEditor
              key={editorConfig.getEditorKey()}
              editable={editorConfig.getEditorEditable()}
              initialValue={editorConfig.getEditorStartupValue()}
              onChange={NullFnc}
              contentManager={contentManagerSite}
            />
          </div>
        </div>
      }
      <BlogPostAdjacentNavigationComponent
        previousPost={previousPost}
        nextPost={nextPost}
      />
    </div>
  )
}