import { useEffect } from 'react';
import { RichTextEditor } from '../../richTextEditor/richTextEditor';
import { useRichTextEditor } from '../../richTextEditor/hooks/useRichTextEditor';
import { useRichTextEditorContentManagerSite } from '../../../../components/sites/editor/common/richTextEditorContentManagerSite/useRichTextEditorContentManagerSite';
import { DynamicPageStaticProvider } from './provider/dynamicPageStaticProvider';
import { SitePageRenderInfo } from '../../../../common-app/sites/models/sitePageRenderInfo';

import './dynamicPageStatic.css'

interface DynamicPageStaticProps {
  siteId: string;
  pageId: string;
  pageRenderInfo: SitePageRenderInfo;
  dataProvider: DynamicPageStaticProvider;
}

export function DynamicPageStaticComponent({
  siteId,
  pageId,
  dataProvider,
}: DynamicPageStaticProps): JSX.Element {

  const editorConfig = useRichTextEditor(true);
  const contentManagerSite = useRichTextEditorContentManagerSite(siteId, pageId);

  useEffect(() => {
    const loadStaticData = async () => {
      // Get the contents from the backend
      const content = await dataProvider.getPageDataStatic(pageId);
      editorConfig.updateReadOnly(true);
      editorConfig.updateInitialValue(content);
    }
    loadStaticData();
  }, [pageId]);

  return <div>
    <RichTextEditor
      key={editorConfig.getEditorKey()}
      editable={editorConfig.getEditorEditable()}
      initialValue={editorConfig.getEditorStartupValue()}
      onChange={(newValue) => {
        editorConfig.updateContents(newValue);
      }}
      contentManager={contentManagerSite}
    />
  </div>

}