
import { Button } from 'react-bootstrap';
import { HorizontalListComponent } from '../../horizontalList/horizontalList';
import './actionsSaveDiscard.css';

interface ActionsSaveDiscardProps {
    canSave: boolean;
    onDiscard: () => void;
}

export function ActionsSaveDiscardComponent({
    canSave,
    onDiscard
}: ActionsSaveDiscardProps): JSX.Element {
    return (
        <HorizontalListComponent>
            <Button
                variant="outline-primary"
                onClick={onDiscard}
                disabled={!canSave}>
                Descartar
            </Button>

            <Button
                variant="primary"
                type="submit"
                disabled={!canSave}>
                Guardar
            </Button>
        </HorizontalListComponent>
    );
}
