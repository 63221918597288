
import classNames from 'classnames';
import { Spanner } from '../../../../common-frontend/components/micro/spanner/spanner';
import { UserQueryAdminList } from '../../../../common-app/userQueries/models/userQueryAdminList';
import { AdminUserQueriesListItemActionsComponent } from '../adminUserQueriesListItemActions/adminUserQueriesListItemActions';
import { DateUtil } from '../../../../common/util/date/dateUtil';

import './adminUserQueriesListItem.css';

interface AdminUserQueriesListItemProps {
    userQuery: UserQueryAdminList;
    isSelected: boolean;
    onItemMarkAsUnread: () => void;
}

export function AdminUserQueriesListItemComponent({
    userQuery,
    isSelected,
    onItemMarkAsUnread
}: AdminUserQueriesListItemProps): JSX.Element {

    const textStyles = classNames(
        { "text-unread": userQuery.isUnread }
    );

    return (
        <div className="admin-user-queries-list-item-component">
            <div className='contact-and-message'>
                <div className={'contact'}>
                    {userQuery.contact}
                </div>
                <div
                    className={classNames("message", textStyles)}
                >
                    {userQuery.message}
                </div>
            </div>

            <div
                className='date-and-actions'
            >
                <div className={classNames('date', textStyles)}>
                    {DateUtil.getPastDateAsHumanReadable(userQuery.creationDate)}
                </div>
                <Spanner></Spanner>
                {
                    isSelected &&
                    <AdminUserQueriesListItemActionsComponent
                        onItemMarkAsUnread={onItemMarkAsUnread}
                    />
                }
            </div>
        </div >
    );
}
