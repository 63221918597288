import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HorizontalListComponent } from "../../../../../common-frontend/components/horizontalList/horizontalList";
import { VerticalListComponent } from "../../../../../common-frontend/components/verticalList/verticalList";
import { useUserSessionContext } from "../../../../contexts/session/hooks/useUserSessionContext";
import { ServiceApiSitesOwnership } from "../../../../../services/api/sites/serviceApiSitesOwnership";
import { ServiceFeatureAnalytics } from "../../../../../services/features/analytics/serviceFeatureAnalytics";

interface SiteSettingsOwnershipActionsProps {
  siteId: string;
}

export function SiteSettingsOwnershipActionsComponent({ siteId }: SiteSettingsOwnershipActionsProps): JSX.Element {

  const userSessionContext = useUserSessionContext();
  const userIsLoggedIn = userSessionContext.userId !== "";

  const handleTakeOwnership = async (): Promise<void> => {

    const deviceId = ServiceFeatureAnalytics.getDeviceIdCreateIfDoesNotExist();

    const result = await ServiceApiSitesOwnership.takeSiteOwnership(
      userSessionContext.userSessionId,
      siteId,
      deviceId
    );

    if (result) {
      // Refresh the page
      window.location.reload();
    }
  }

  const getExplanationMessage = (): string => {

    if (userIsLoggedIn) {
      return "Associe este site à sua conta sem custos e edite-o em qualquer lugar.";
    }
    else {
      return "Este site foi criado sem ter uma sessão iniciada. Para poder editar o site noutros dispositivos, é necessário criar uma conta de utilizador e associar ao site. Esta operação não tem custos.";
    }
  }

  const renderActionButtons = (): JSX.Element => {

    if (userIsLoggedIn) {
      return (
        <HorizontalListComponent>
          <Button onClick={handleTakeOwnership}>
            Associar à minha conta
          </Button>
        </HorizontalListComponent>
      )
    }
    else {
      return (
        <HorizontalListComponent>
          <Link to="/signup">
            <Button>
              Criar conta
            </Button>
          </Link>

          <Link to="/signin">
            <Button variant="outline-primary">
              Iniciar sessão
            </Button>
          </Link>
        </HorizontalListComponent>
      )
    }
  }

  return (
    <div className="site-settings-ownership-actions">
      <VerticalListComponent>
        <div>{getExplanationMessage()}</div>
        <div>{renderActionButtons()}</div>
      </VerticalListComponent>
    </div>
  );
}