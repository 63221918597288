import { Button } from "react-bootstrap";
import { useState } from "react";
import { QuestionDialogComponent } from "../questionDialog/questionDialog";

interface ButtonDeleteProps {
  itemName: string;
  onDelete: () => void;
}

export function ButtonDelete({ itemName, onDelete }: ButtonDeleteProps): JSX.Element {

  const [showDialog, setShowDialog] = useState(false);

  return (
    <div>
      <Button
        className="btn btn-danger"
        onClick={() => setShowDialog(true)}
      >Eliminar</Button>

      <QuestionDialogComponent
        showDialog={showDialog}
        title="Eliminar"
        question={`Tem a certeza que deseja eliminar ${itemName}?`}
        labelPositive="Eliminar"
        positiveIsDanger={true}
        onPositive={() => {
          setShowDialog(false);
          onDelete();
        }}
        onNegative={() => {
          setShowDialog(false);
        }}
      />

    </div>
  )
}