import './horizontalList.css';

interface HorizontalListProps {
    children: React.ReactNode;
    className?: string;
}

export function HorizontalListComponent({ children, className }: HorizontalListProps): JSX.Element {
    return (
        <div className={`horizontal-list-component ${className}`}>
            {children}
        </div>
    );
}
