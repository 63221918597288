import { useState } from "react";

export function useRichTextEditor(editorReadOnly = false) {

  const [readOnly, setReadOnly] = useState<boolean>(editorReadOnly);

  // This is to force a new instance of editor when there's some changes
  const [editorKey, setEditorKey] = useState(1);

  // Inital value sent to the editor
  const [editorStartupValue, setEditorStartupValue] = useState(JSON.stringify([]));

  // Real time contents of the editor
  const [editorContents, setEditorContents] = useState('');

  const updateEditorInstance = () => {
    setEditorKey(editorKey + 1);
  }

  const updateContents = (contents: string) => {
    setEditorContents(contents);
  }

  const updateInitialValue = (newValue: string) => {
    setEditorStartupValue(newValue);
    updateContents(newValue);
    updateEditorInstance();
  }

  const updateReadOnly = (value: boolean) => {
    setReadOnly(value);
    updateEditorInstance();
  }

  return {
    updateReadOnly,
    updateInitialValue,
    updateContents,

    getEditorEditable: () => !readOnly,
    getEditorStartupValue: () => editorStartupValue,
    getEditorContents: () => editorContents,

    getEditorKey: () => editorKey,

    hasChanges: () => editorStartupValue !== editorContents
  };
}
