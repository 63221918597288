import { useMemo } from "react";
import { SitePageRenderInfo } from "../../../../../common-app/sites/models/sitePageRenderInfo";
import { DynamicPageContactsComponent } from "../../../../../common-frontend/components/dynamicPages/contacts/dynamicPageContacts";
import { DynamicPageContactsServiceProvider } from "./dynamicPageContactsServiceProvider";

interface DynamicPageContactsWrapperProps {
  siteId: string;
  pageId: string;
  pageRenderInfo: SitePageRenderInfo;
}

export function DynamicPageContactsWrapperComponent({
  siteId,
  pageId,
  pageRenderInfo: pagePresentationInfo
}: DynamicPageContactsWrapperProps): JSX.Element {

  const dataProvider = useMemo(() => new DynamicPageContactsServiceProvider(), []);

  return (
    <div>
      <DynamicPageContactsComponent
        siteId={siteId}
        pageId={pageId}
        dataProvider={dataProvider}
      />
    </div>
  );
}