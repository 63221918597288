import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { SitePageType } from '../../../../../../common-app/sites/enums/sitePageType';
import { SitePageTypesInfo } from '../../../../../../common-frontend/features/sites/sitePageTypesInfo';

import './addSitePageDialog.css';

interface AddSitePageDialogProps {
  show: boolean;
  onPageSelected: (pageType: SitePageType) => void;
  onDismiss: () => void;
}

export function AddSitePageDialogComponent({
  show,
  onPageSelected,
  onDismiss
}: AddSitePageDialogProps): JSX.Element {

  const sitePageTypesInfo = useMemo(() => SitePageTypesInfo.GetPageTypes(), []);
  const sitePageTypesInfoToDisplay = useMemo(() => sitePageTypesInfo.filter(i => i.showInSelection), [sitePageTypesInfo]);

  const [selectedPageType, setSelectedPage] = useState<SitePageType>(SitePageType.Unknown);

  const handlePageTypeSelected = (pageType: SitePageType) => {

    if (selectedPageType === pageType) {
      setSelectedPage(SitePageType.Unknown);
    }
    else {
      setSelectedPage(pageType);
    }
  }

  const handleAddPage = () => {
    if (selectedPageType !== undefined) {
      onPageSelected(selectedPageType);
    }
  }

  return (
    <Modal
      className='add-site-page-dialog-component'
      size="xl"
      show={show}
      centered
    >
      <Modal.Header>
        <Modal.Title>Adicionar nova página</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='page-type-items'>

          {sitePageTypesInfoToDisplay.map((sitePageInfo, index) => (
            <div
              key={index}
              onClick={() => handlePageTypeSelected(sitePageInfo.type)}
              className={classNames("page-type-item shadow", {
                "page-type-item-selected": sitePageInfo.type === selectedPageType
              })} >
              {sitePageInfo.name}
            </div>
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={onDismiss}>
          Fechar
        </Button>

        <Button
          variant="primary"
          onClick={handleAddPage}
          disabled={selectedPageType === SitePageType.Unknown}
        >
          Adicionar
        </Button>
      </Modal.Footer>
    </Modal >
  );
}
