import { Button, Container, Form } from "react-bootstrap";
import { PageTitle } from "../../../common-frontend/components/pageTitle/pageTitle";
import { Link, useNavigate } from "react-router-dom";
import { FormEvent, useState } from "react";
import { ValidationEmail } from "../../../common/util/validation/email";
import { ServiceApiSystem } from "../../../services/api/serviceApiSystem";
import { LoginResult } from "../../../common-app/system/enums/loginResult";
import { VerticalListComponent } from "../../../common-frontend/components/verticalList/verticalList";
import { FormEditComponent } from "../../../common-frontend/components/form/formEdit/formEdit";
import { LocalStorageSession } from "../../../library/localStorage/session/session";
import { ErrorListComponent } from "../../../common-frontend/components/errorList/errorList";
import { useUserSessionContext } from "../../../components/contexts/session/hooks/useUserSessionContext";

import "./pageSignIn.css";

enum SignInButtonLabelOption {
  SignIn = "Entrar",
  SigningIn = "A autenticar..."
}

export function PageSignIn(): JSX.Element {

  const navigate = useNavigate();
  const userSessionContext = useUserSessionContext();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [buttonSignInLabel, setButtonSignInLabel] = useState<string>(SignInButtonLabelOption.SignIn);
  const [isSigningInAccount, setIsSigningInAccount] = useState<boolean>(false);


  const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let errors: string[] = [];

    // Use a regular expression to check if the email is valid
    if (ValidationEmail.isValid(email) === false) {
      errors.push("O email não é válido.");
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      return;
    }

    setButtonSignInLabel(SignInButtonLabelOption.SigningIn);
    setIsSigningInAccount(true);
    setErrorMessages([]);

    const result = await ServiceApiSystem.login(email, password);

    setIsSigningInAccount(false);
    setButtonSignInLabel(SignInButtonLabelOption.SignIn);

    switch (result.result) {
      case LoginResult.Success:

        // Save this to the local storage
        LocalStorageSession.saveSessionId(result.sessionId);
        LocalStorageSession.saveUserId(result.userId);

        // Save this to the session context
        userSessionContext.setSessionInformation(result.sessionId, result.userId);

        // Navigate to the user sites page
        navigate("/user-sites");
        break;

      case LoginResult.InvalidMailOrPassword:
        errors.push("O utilizador ou a senha são inválidos");
        break;
    }

    setErrorMessages(errors);
  }

  return (
    <div className="page-sign-in">
      <Container>
        <div className="content-parent">

          <div className="content">

            <PageTitle
              title="Entrar"
            />
            <Form onSubmit={(e) => { handleSignIn(e) }}>

              {/* Add an input for the user's mail */}
              <VerticalListComponent>
                <Form.Group controlId="formBasicEmail" >
                  <Form.Label>Email</Form.Label>
                  <FormEditComponent
                    type="text"
                    value={email}
                    onChange={setEmail}
                  />
                </Form.Group>

                {/* Add an input for the user's password */}
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <div className="buttons-holder">
                  <Button
                    type="submit"
                    variant="primary"
                    className=""
                    disabled={isSigningInAccount}
                  >{buttonSignInLabel}</Button>

                  <Link to={'/signup'}>
                    <Button
                      type="submit"
                      variant="outline-primary">Não tenho conta</Button>
                  </Link>
                </div>

                {/* Add a component to display errors */}
                <ErrorListComponent errorMessages={errorMessages} />

              </VerticalListComponent>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}