import { YoutubeShort } from "../../../youtubeVideo/youtubeShort";
import { CustomElementYoutubeShort } from "../../types";
import { RenderComponentProps } from "../renderComponentProps";

import './renderYoutubeShort.css';

export function RenderYoutubeShortComponent({
  element,
  attributes,
  children
}: RenderComponentProps): JSX.Element {

  const youtubeShortElement = element as CustomElementYoutubeShort;

  return (
    <div
      className="render-youtube-short-component"
      contentEditable={false}
    >
      <YoutubeShort videoId={youtubeShortElement.videoId} />

      {youtubeShortElement.caption.length > 0 &&
        <div
          className="render-youtube-short-component-caption">
          {youtubeShortElement.caption}
        </div>
      }

      {children}
    </div>
  );
}