import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CustomElement, CustomElementTypesEnum } from "../../types";
import { ElementSettingsConfig } from "../elementSettingsConfig";
import { RichTextEditorContentManager } from "../../richTextEditorContentManager";
import { ElementName } from "../information/elementName";

import './elementCreation.css';

interface ElementCreationProps {
  contentManager: RichTextEditorContentManager;
  show: boolean;
  elementType: CustomElementTypesEnum;
  onConfirm: (element: CustomElement) => void;
  onDismiss: () => void;
}

export function ElementCreationComponent({
  contentManager,
  show,
  elementType,
  onConfirm,
  onDismiss
}: ElementCreationProps): JSX.Element {

  const [elementCanBeCreated, setElementCanBeCreated] = useState<boolean>(false);
  const [elementName, setElementName] = useState<string | undefined>(undefined);

  let RenderComponent = ElementSettingsConfig.getElementCreationComponent(elementType);
  const createElementRef = useRef<any>(null);

  useEffect(() => {
    setElementName(ElementName.getElementName(elementType));
  }, [elementType]);

  const handleClose = () => {
    onDismiss();
  }

  const handleCreate = () => {

    if (createElementRef.current) {
      const newElement = createElementRef.current.createElement();
      if (!newElement) {
        return;
      }
      onConfirm(newElement);
    }
  }

  return (
    <div className="element-creation-component">
      <Modal
        show={show}
        size="lg"
        centered
        backdrop="static"
        onHide={onDismiss}>

        <Modal.Header closeButton>
          <Modal.Title>Adicionar {elementName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='element-edit-component-render-area'>
            {RenderComponent &&
              <RenderComponent
                ref={createElementRef}
                contentManager={contentManager}
                elementType={elementType}
                elementCanBeCreated={setElementCanBeCreated}
              />
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Fechar
          </Button>
          <Button variant="primary"
            onClick={handleCreate}
            disabled={!elementCanBeCreated}
          >
            Criar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}