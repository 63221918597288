import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { Spanner } from '../../../../common-frontend/components/micro/spanner/spanner';
import { ButtonCopyToClipboardComponent } from '../../../../common-frontend/components/buttonCopyToClipboard/buttonCopyToClipboard';

import './sitePostCreationPopup.css';

interface SitePostCreationPopupProps {
    show: boolean;
    onClose: () => void;
}

export function SitePostCreationPopupComponent({ show, onClose }: SitePostCreationPopupProps): JSX.Element {

    const location = useLocation();

    return (
        <div className="site-post-creation-popup-component">
            <Modal
                show={show}
                centered
            >
                <Modal.Header>
                    <Modal.Title>O primeiro passo está feito!</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <h5 style={{ marginBottom: "var(--size-8)" }}>A sua página está pronta!</h5>
                    <p>Pode aceder a esta página em qualquer parte do mundo!</p>
                    <div style={{ marginBottom: "var(--size-8)" }}>
                        <HorizontalListComponent>
                            <div><i>www.pixelvaidoso.com{location.pathname}</i></div>
                            <Spanner />
                            <ButtonCopyToClipboardComponent
                                size='sm'
                                valueToCopy={`www.pixelvaidoso.com${location.pathname}`}
                            />
                        </HorizontalListComponent>
                    </div>

                    <div style={{ marginBottom: "var(--size-8)" }}>
                        <p><b>O que fazer a seguir</b></p>
                        <p>Use o botão <i className="bi bi-gear" /> para editar esta página, adicionar novas páginas e gerir o seu site.</p>
                    </div>

                    <div>
                        <p>Se necesitar de apoio, ou desejar um website mais personalizado ou software à sua medida, não hesite em contactar.</p>
                    </div>


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
