import { Container } from "react-bootstrap";
import { CreateSiteActionComponent } from "../../components/home/createSiteAction/createSiteAction";
import { PageTitle } from "../../common-frontend/components/pageTitle/pageTitle";
import { VerticalWhiteSpaceComponent } from "../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace";
import { Link } from "react-router-dom";

import "./page1Click.css";

export function Page1Click(): JSX.Element {
  return (
    <Container>
      <div className="page-1-click-component">
        <PageTitle title="Sites 1-click"></PageTitle>

        <div className="banner">

          Crie websites num minuto, facilmente, e sem custos.

        </div>
        <VerticalWhiteSpaceComponent ammount={1} />

        <h4>
          Como funciona?
        </h4>
        <div className="text-wall">
          <p>
            Com o sistema 1-click, pode criar um website sem precisar de ter qualquer conhecimento técnico. Basta escolher um nome para o site e este fica online imediatamente.
          </p>
          <p>
            Com o nosso editor, pode depois adicionar páginas, conteúdo e imagens ao seu site. Tão simples como escrever um email ou um post numa rede social.
          </p>
          <p>
            Se necessitar de um website mais complexo ou com outras funcionalidades, consulte a nossa página de <Link to="/services">serviços</Link>.
          </p>
          <p>
            Experimente sem precisar de se registar ou de fornecer qualquer informação pessoal.
          </p>
        </div>
        <VerticalWhiteSpaceComponent ammount={1} />

        <CreateSiteActionComponent />

        <VerticalWhiteSpaceComponent ammount={1} />

        <h5>Exemplos</h5>
        <ul>
          <li>
            Florista da Maria
          </li>
          <li>
            Padaria do João
          </li>
          <li>
            Galeria Intemporal
          </li>
          <li>
            Cristina Gomes
          </li>
        </ul>

      </div>
    </Container>
  );
}