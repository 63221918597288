import { YoutubeVideo } from "../../../../youtubeVideo/youtubeVideo";

import "./elementConfigurationSnippetYoutubeVideoPreview.css";

interface ElementConfigurationSnippetYoutubeVideoPreviewProps {
  videoId: string;
}

export function ElementConfigurationSnippetYoutubeVideoPreviewComponent(
  { videoId }: ElementConfigurationSnippetYoutubeVideoPreviewProps
): JSX.Element {
  return <div className="element-configuration-snippet-youtube-video-preview-component">
    {videoId === "" &&
      <div className="no-video-id"><i className="bi bi-camera-video-off" /></div>
    }
    {videoId !== "" &&
      <YoutubeVideo videoId={videoId} />
    }
  </div>
}
