import { ApiService } from "../../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../../common-frontend/services/api/backendServerEndpointProvider";
import { SitePageType } from "../../../common-app/sites/enums/sitePageType";
import { SitePageEditorList } from "../../../common-app/sites/models/sitePageEditorList";
import { SitePageSelect } from "../../../common-app/sites/models/sitePageSelect";
import { SitePageEditorInfo } from "../../../common-app/sites/models/sitePageEditorInfo";
import { SitePageParentTreeNode } from "../../../common-app/sites/models/sitePageParentTreeNode";
import { SitePage } from "../../../common-app/sites/models/sitePage";
import {
  CreateSitePageRequestBody,
  CreateSitePageRouteDef
} from "../../../common-app/sites/actions/pages/createSitePage";
import {
  DeleteSitePageRouteDef
} from "../../../common-app/sites/actions/pages/deleteSitePage";
import {
  DuplicateSitePageRequestBody,
  DuplicateSitePageRouteDef
} from "../../../common-app/sites/actions/pages/duplicateSitePage";
import {
  GetSitePageEditorInfoRouteDef,
  GetSitePageEditorInfoResponse
} from "../../../common-app/sites/actions/pages/getSitePageEditorInfo";
import {
  GetSitePagesResponse,
  GetSitePagesRouteDef
} from "../../../common-app/sites/actions/pages/getSitePages";
import {
  GetSitePagesAvailableForHomePageResponse,
  GetSitePagesAvailableForHomePageRouteDef
} from "../../../common-app/sites/actions/pages/getSitePagesAvailableForHomePage";
import {
  GetSitePagesEditorListResponse,
  GetSitePagesEditorListRouteDef
} from "../../../common-app/sites/actions/pages/getSitePagesEditorList";
import {
  GetSitePagesParentTreeResponse,
  GetSitePagesParentTreeRouteDef
} from "../../../common-app/sites/actions/pages/getSitePagesParentTree";
import {
  ReorderSitePagesRequestBody,
  ReorderSitePagesRouteDef
} from "../../../common-app/sites/actions/pages/reorderSitePages";
import {
  UpdateSitePageRequestBody,
  UpdateSitePageRouteDef
} from "../../../common-app/sites/actions/pages/updateSitePage";
import {
  UpdateSitePageParentRequestBody,
  UpdateSitePageParentRouteDef
} from "../../../common-app/sites/actions/pages/updateSitePageParent";
import {
  Create1ClickSiteRequestBody,
  Create1ClickSiteRouteDef
} from "../../../common-app/sites/actions/sites/create1ClickSite";
import {
  DeleteSiteRouteDef
} from "../../../common-app/sites/actions/sites/deleteSite";
import {
  GetIsSiteDomainAvailableResponse,
  GetIsSiteDomainAvailableRouteDef
} from "../../../common-app/sites/actions/sites/getIsSiteDomainAvailable";
import {
  GetPossibleSiteDomainResponse,
  GetPossibleSiteDomainRouteDef
} from "../../../common-app/sites/actions/sites/getPossibleSiteDomain";
import {
  GetSiteInfoResponse,
  GetSiteInfoRouteDef
} from "../../../common-app/sites/actions/sites/getSiteInfo";
import {
  UpdateSiteRequestBody,
  UpdateSiteRouteDef
} from "../../../common-app/sites/actions/sites/updateSite";
import {
  GetSitePageNameResponse,
  GetSitePageNameRouteDef
} from "../../../common-app/sites/actions/pages/getSitePageName";
import {
  SiteInfo
} from "../../../common-app/sites/models/siteInfo";
import {
  UpdateSitePageFeaturesRequestBody,
  UpdateSitePageFeaturesRouteDef
} from "../../../common-app/sites/actions/pages/updateSitePageFeatures";
import {
  GetSitePageFeaturesResponse,
  GetSitePageFeaturesRouteDef
} from "../../../common-app/sites/actions/pages/getSitePageFeatures";
import {
  ServiceFeatureAnalytics
} from "../../features/analytics/serviceFeatureAnalytics";
import { RequestHeaderHelper } from "../../requestHeaderData/requestHeaderHelper";
import { SitePageFeature } from "../../../common-app/sites/enums/sitePageFeature";

export class ServiceApiSitesManagement extends ApiService {
  static async create1ClickSite(
    siteId: string,
    websiteName: string,
    siteOwner: string
  ): Promise<boolean> {

    const deviceId = ServiceFeatureAnalytics.getDeviceIdCreateIfDoesNotExist();

    const routeDef = new Create1ClickSiteRouteDef();
    const requestBody = new Create1ClickSiteRequestBody(
      siteId,
      websiteName,
      deviceId,
      siteOwner
    );
    const requestOptions = this.getRequestOptions(
      routeDef,
      requestBody
    );

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );
  }

  static async getSiteInfo(siteId: string): Promise<SiteInfo | undefined> {
    const routeDef = new GetSiteInfoRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSiteInfoResponse>(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return undefined;
    }

    return response.siteInfo;
  }

  static async getSiteDomain(siteId: string): Promise<string | undefined> {

    const routeDef = new GetSiteInfoRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSiteInfoResponse>(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return undefined;
    }

    return response.siteInfo.domainName;
  }

  static async deleteSite(siteId: string): Promise<boolean> {

    const routeDef = new DeleteSiteRouteDef();

    const requestOptions = this.getRequestOptions(
      routeDef,
      undefined
    );

    return this.DoRequest(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async updateSite(siteId: string, name: string, domain: string, homePageId: string, isActive: boolean): Promise<boolean> {

    const routeDef = new UpdateSiteRouteDef();
    const body = new UpdateSiteRequestBody(name, domain, isActive, homePageId);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId));
  }

  static async getSitePagesAvailableForHomePage(siteId: string): Promise<SitePageSelect[]> {

    const routeDef = new GetSitePagesAvailableForHomePageRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSitePagesAvailableForHomePageResponse>(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return [];
    }

    return response.pages;
  }

  static async getSitePages(siteId: string): Promise<SitePage[]> {

    const routeDef = new GetSitePagesRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSitePagesResponse>(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return [];
    }

    return response.pages;
  }

  static async getSitePageName(siteId: string, pageId: string): Promise<string | undefined> {

    const routeDef = new GetSitePageNameRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSitePageNameResponse>(
      routeDef.route(siteId, pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return undefined;
    }

    return response.pageName;
  }

  static async getSitePagesEditorList(siteId: string): Promise<SitePageEditorList[]> {

    const routeDef = new GetSitePagesEditorListRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSitePagesEditorListResponse>(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return [];
    }

    return response.pages;
  }

  static async getSitePagesParentTree(siteId: string, pageId: string): Promise<SitePageParentTreeNode[]> {
    const routeDef = new GetSitePagesParentTreeRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSitePagesParentTreeResponse>(
      routeDef.route(siteId, pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return [];
    }

    return response.pages;
  }

  static async addSitePage(
    siteId: string,
    pageId: string,
    newPageType: SitePageType,
    pageName: string,
    anchorPageId: string | undefined): Promise<boolean> {
    const requestBody = new CreateSitePageRequestBody(
      siteId,
      pageId,
      newPageType,
      pageName,
      anchorPageId
    );

    const routeDef = new CreateSitePageRouteDef();
    const requestOptions = this.getRequestOptions(
      routeDef,
      requestBody
    );

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async duplicateSitePage(siteId: string, existingPageId: string, newPageId: string): Promise<boolean> {

    const routeDef = new DuplicateSitePageRouteDef();
    const requestBody = new DuplicateSitePageRequestBody(
      siteId,
      existingPageId,
      newPageId
    );

    const requestOptions = this.getRequestOptions(
      routeDef,
      requestBody
    );

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async deleteSitePage(siteId: string, pageId: string): Promise<boolean> {

    const routeDef = new DeleteSitePageRouteDef();
    const requestOptions = this.getRequestOptions(
      routeDef,
      undefined
    );

    return this.DoRequest(
      routeDef.route(siteId, pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async reorderSitePages(siteId: string, parentPageId: string, pageIds: string[]): Promise<boolean> {

    const routeDef = new ReorderSitePagesRouteDef();
    const requestBody = new ReorderSitePagesRequestBody(pageIds);

    const requestOptions = this.getRequestOptions(
      routeDef,
      requestBody
    );

    return this.DoRequest(
      routeDef.route(siteId, parentPageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async getIsSiteDomainAvailable(siteId: string, tentativeDomain: string): Promise<boolean> {

    const routeDef = new GetIsSiteDomainAvailableRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetIsSiteDomainAvailableResponse>(
      routeDef.route(siteId, tentativeDomain),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return false;
    }

    return response.isAvailable;
  }

  static async getPossibleSiteDomain(siteId: string, siteName: string): Promise<string> {

    const routeDef = new GetPossibleSiteDomainRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetPossibleSiteDomainResponse>(
      routeDef.route(siteId, siteName),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return '';
    }

    return response.domain;
  }

  static async getPageEditorInfo(site: string, page: string): Promise<SitePageEditorInfo | undefined> {

    const routeDef = new GetSitePageEditorInfoRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse(
      routeDef.route(site, page),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return undefined;
    }

    const responsePageType = response as GetSitePageEditorInfoResponse;
    return responsePageType.pageInfo;
  }

  static async updateSitePage(
    siteId: string,
    pageId: string,
    name: string,
    isActive: boolean): Promise<boolean> {

    const routeDef = new UpdateSitePageRouteDef();
    const body = new UpdateSitePageRequestBody(name, isActive);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(siteId, pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async updateSitePageParent(
    siteId: string,
    pageId: string,
    parentPageId: string): Promise<boolean> {

    const routeDef = new UpdateSitePageParentRouteDef();
    const body = new UpdateSitePageParentRequestBody(parentPageId);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(siteId, pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async updatePageFeatures(
    siteId: string,
    pageId: string,
    features: SitePageFeature[]): Promise<boolean> {

    const routeDef = new UpdateSitePageFeaturesRouteDef();
    const body = new UpdateSitePageFeaturesRequestBody(features);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(siteId, pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async getPageFeatures(siteId: string, pageId: string): Promise<SitePageFeature[]> {

    const routeDef = new GetSitePageFeaturesRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse(
      routeDef.route(siteId, pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return [];
    }

    const responsePageType = response as GetSitePageFeaturesResponse;
    return responsePageType.features;
  }
}
