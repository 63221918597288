import { useState } from "react";
import { Button } from "react-bootstrap";
import { VerticalListComponent } from "../../../verticalList/verticalList";
import { CustomElementUserQuery } from "../../types";
import { RenderComponentProps } from "../renderComponentProps";
import { FormEditComponent } from "../../../form/formEdit/formEdit";
import { FormTextAreaComponent } from "../../../form/formTextArea/formTextArea";
import { useRichTextEditorOperationsContext } from "../../contexts/operationsContext/hooks/useRichTextEditorOperationsContext";
import { useRichTextEditorDataContext } from "../../contexts/dataContext/hooks/useRichTextEditorDataContext";
import { GuidUtil } from "../../../../../common/util/guid/guid";

import './renderUserQuery.css';

export function RenderUserQueryComponent({
  element,
  attributes,
  children
}: RenderComponentProps): JSX.Element {

  const userQueryElement = element as CustomElementUserQuery;

  const operationsContext = useRichTextEditorOperationsContext();
  const dataContext = useRichTextEditorDataContext();

  const [contact, setContact] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const validDataToSubmit = (contact !== "") || (subject !== "") || (message !== "");
  const submitEnabled = !submitted && validDataToSubmit;

  const handleSubmit = () => {
    if (submitEnabled === false) {
      return;
    }

    dataContext.insertUserQuery(
      dataContext.siteId,
      dataContext.pageId,
      userQueryElement.userQueryId,
      GuidUtil.GenerateNewGuid(),
      contact,
      subject,
      message);

    setSubmitted(true);
  }

  return (
    <div {...attributes} className="render-user-query-component">

      <div contentEditable={false} className="user-query-parent">
        <div className="user-query-title">
          {userQueryElement.name}
        </div>
        <VerticalListComponent>
          <div>
            Telefone ou endereço de email
            <FormEditComponent
              type="text"
              readonly={operationsContext.editing || submitted}
              value={contact}
              onChange={setContact} />
          </div>

          <div>
            Assunto
            <FormEditComponent
              type="text"
              readonly={operationsContext.editing || submitted}
              value={subject}
              onChange={setSubject}
            />
          </div>

          <div>
            Mensagem
            <FormTextAreaComponent
              lines={5}
              readonly={operationsContext.editing || submitted}
              value={message}
              onChange={setMessage} />
          </div>

          <Button
            onClick={handleSubmit}
            disabled={!submitEnabled}
          >
            {!submitted ? "Enviar" : "Obrigado pela sua mensagem. Entraremos em contacto consigo o mais rápido possível."}
          </Button>
        </VerticalListComponent>
      </div>
      {children}
    </div>
  );
}