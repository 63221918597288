import { BaseEditor, Editor, Element, Node, NodeEntry, Path, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { EditorUtil } from "./richTextEditorUtil";
import {
  CustomElement,
  CustomElementParagraphVersion,
  CustomElementTypesEnum,
  CustomText
} from "./types";

export class EditorKeyboard {

  public static handleImageRelatedEvents(
    editor: BaseEditor & ReactEditor,
    event: React.KeyboardEvent<globalThis.Element>) {

    if (event.key === 'ArrowUp') {
      this.handleArrowUp(editor, event);
    }
    if (event.key === 'ArrowDown') {
      this.handleArrowDown(editor, event);
    }
    if (event.key === 'Delete') {
      this.handleDelete(editor, event);
    }
    if (event.key === 'Backspace') {
      this.handleBackspace(editor, event);
    }
    if (event.key === 'Enter') {
      this.handleEnter(editor, event);
    }
  }

  private static handleArrowUp = (editor: BaseEditor & ReactEditor, event: React.KeyboardEvent<globalThis.Element>) => {

    const currentSelectedNode = this.getCurrentSelectedElement(editor);
    const elementFromNode = this.getElementFromNode(currentSelectedNode);

    if (elementFromNode === undefined) {
      return;
    }

    if (this.isElementAnImage(elementFromNode)) {

      this.handleArrowUpOnImage(editor, elementFromNode, event);
    }
    else {
      this.handleArrowUpOnNonImage(editor, elementFromNode, event);
    }
  }

  private static handleArrowUpOnImage = (editor: BaseEditor & ReactEditor, elementFromNode: CustomElement, event: React.KeyboardEvent<globalThis.Element>) => {
    // Put the selection at the beggining of the next node
    this.moveSelectionToPreviousNode(editor, elementFromNode);

    event.preventDefault();
  }

  private static handleArrowUpOnNonImage = (editor: BaseEditor & ReactEditor, elementFromNode: CustomElement, event: React.KeyboardEvent<globalThis.Element>) => {
    // Check if the next node is an image
    // If it is, we need to put the selection in that node
    const previousNodeEntry = this.getPreviousNode(editor, elementFromNode);
    if (previousNodeEntry === undefined) {
      return;
    }

    const nextElement = previousNodeEntry[0] as CustomElement;

    if (this.isElementAnImage(nextElement)) {
      this.moveSelectionToPreviousNode(editor, elementFromNode);
      event.preventDefault();
    }
  }

  private static handleArrowDown = (editor: BaseEditor & ReactEditor, event: React.KeyboardEvent<globalThis.Element>) => {

    const currentSelectedNode = this.getCurrentSelectedElement(editor);
    const elementFromNode = this.getElementFromNode(currentSelectedNode);

    if (elementFromNode === undefined) {
      return;
    }

    if (this.isElementAnImage(elementFromNode)) {
      this.handleArrowDownOnImage(editor, elementFromNode, event);
    }
    else {
      this.handleArrowDownOnNonImage(editor, elementFromNode, event);
    }
  }

  private static handleArrowDownOnImage = (editor: BaseEditor & ReactEditor, elementFromNode: CustomElement, event: React.KeyboardEvent<globalThis.Element>) => {
    // Put the selection at the beggining of the next node
    this.moveSelectionToNextNode(editor, elementFromNode);

    event.preventDefault();
  }

  private static handleArrowDownOnNonImage = (editor: BaseEditor & ReactEditor, elementFromNode: CustomElement, event: React.KeyboardEvent<globalThis.Element>) => {

    // Check if the next node is an image
    // If it is, we need to put the selection in that node
    const nextNodeEntry = this.getNextNode(editor, elementFromNode);
    if (nextNodeEntry === undefined) {
      return;
    }

    const nextElement = nextNodeEntry[0] as CustomElement;

    if (this.isElementAnImage(nextElement)) {
      this.moveSelectionToNextNode(editor, elementFromNode);
      event.preventDefault();
    }
  }

  private static handleDelete = (editor: BaseEditor & ReactEditor, event: React.KeyboardEvent<globalThis.Element>) => {

    const currentSelectedNode = this.getCurrentSelectedElement(editor);
    const elementFromNode = this.getElementFromNode(currentSelectedNode);

    if (elementFromNode === undefined) {
      return;
    }

    if (EditorUtil.IsBlockTypeDeletable(elementFromNode.type) === false) {
      return;
    }

    const firstChildren = elementFromNode.children[0] as CustomText;
    const text = firstChildren.text;

    if (text === "") {
      const path = ReactEditor.findPath(editor, elementFromNode);
      Transforms.removeNodes(editor, { at: path });
      event.preventDefault();
    }

    return;
  }

  private static handleBackspace = (editor: BaseEditor & ReactEditor, event: React.KeyboardEvent<globalThis.Element>) => {

    const currentSelectedNode = this.getCurrentSelectedElement(editor);
    const elementFromNode = this.getElementFromNode(currentSelectedNode);

    if (elementFromNode === undefined) {
      return;
    }

    if (EditorUtil.IsBlockTypeDeletable(elementFromNode.type) === false) {
      return;
    }

    const firstChildren = elementFromNode.children[0] as CustomText;
    const text = firstChildren.text;

    if (text !== "") {
      return;
    }

    const previousNodeEntry = this.getPreviousNode(editor, elementFromNode);
    if (previousNodeEntry === undefined) {
      return;
    }

    const nextElement = previousNodeEntry[0] as CustomElement;

    if (this.isElementAnImage(nextElement) === false) {
      return;
    }

    const path = ReactEditor.findPath(editor, elementFromNode);
    Transforms.removeNodes(editor, { at: path });
    this.moveSelectionToPreviousNode(editor, elementFromNode);
    event.preventDefault();

    return;
  }

  private static handleEnter = (editor: BaseEditor & ReactEditor, event: React.KeyboardEvent<globalThis.Element>) => {
    // We need to check if the current item is an image
    // If it is, we need to create a new paragraph after the image
    const currentSelectedNode = this.getCurrentSelectedElement(editor);
    const elementFromNode = this.getElementFromNode(currentSelectedNode);

    if (elementFromNode === undefined) {
      return;
    }

    if (this.isElementAnImage(elementFromNode) === false) {
      return;
    }

    // Get the path of the currently selected element
    const path = ReactEditor.findPath(editor, elementFromNode);
    const newPath = Path.next(path);
    const newParagraph: CustomElement = {
      type: CustomElementTypesEnum.Paragraph,
      version: CustomElementParagraphVersion,
      children: [{ text: '' }]
    };
    Transforms.insertNodes(editor, newParagraph, { at: newPath, select: true });

    event.preventDefault();
  }

  private static moveSelectionToPreviousNode = (editor: BaseEditor & ReactEditor, elementFromNode: CustomElement) => {

    const prevNodeEntry = this.getPreviousNode(editor, elementFromNode);
    if (prevNodeEntry === undefined) {
      return;
    }

    // Put the selection at the end of the previous node
    const [, prevPath] = prevNodeEntry as NodeEntry<Element>;
    const end = Editor.end(editor, prevPath);
    Transforms.select(editor, end);
  }

  private static moveSelectionToNextNode = (editor: BaseEditor & ReactEditor, elementFromNode: CustomElement) => {

    const nextNodeEntry = this.getNextNode(editor, elementFromNode);
    if (nextNodeEntry === undefined) {
      return;
    }

    // Put the selection at the beggining of the next node
    const [, nextPath] = nextNodeEntry as NodeEntry<Element>;
    const start = Editor.start(editor, nextPath);
    Transforms.select(editor, start);
  }

  private static getCurrentSelectedElement(editor: BaseEditor & ReactEditor): NodeEntry<CustomElement> | undefined {
    const [match] = editor.nodes({
      match: (n) => Element.isElement(n),
    });

    return match ? match as NodeEntry<CustomElement> : undefined;
  }

  private static getElementFromNode(node: NodeEntry<CustomElement> | undefined): CustomElement | undefined {
    if (node) {
      return node[0];
    }
    return undefined;
  }

  private static isElementAnImage(element: CustomElement | undefined): boolean {
    return element?.type === 'image' || element?.type === 'image-user' || element?.type === 'image-user-gallery';
  }

  private static getPreviousNode(editor: BaseEditor & ReactEditor, element: CustomElement): NodeEntry<Node> | undefined {
    try {
      const path = ReactEditor.findPath(editor, element);
      return editor.previous({ at: path });
    }
    catch (e) {
      console.warn("Failed to get previous node", e);
      return undefined;
    }
  }

  private static getNextNode(editor: BaseEditor & ReactEditor, element: CustomElement,): NodeEntry<Node> | undefined {
    const path = ReactEditor.findPath(editor, element);
    return editor.next({ at: path });
  }
}