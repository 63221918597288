export class ArrayUtil {

  public static findIndexAndItem<T>(
    array: T[],
    predicate: (item: T) => boolean
  ): {
    index: number,
    item: T | undefined
  } {
    for (let i = 0; i < array.length; i++) {
      if (predicate(array[i])) {
        return { index: i, item: array[i] };
      }
    }
    return { index: -1, item: undefined };
  }

  public static allofTheSame<T, Q>(
    array: T[],
    predicate: (item: T) => Q
  ): boolean {
    if (array.length === 0) {
      return true;
    }

    const firstValue = predicate(array[0]);

    for (let i = 1; i < array.length; i++) {
      if (predicate(array[i]) !== firstValue) {
        return false;
      }
    }

    return true;
  }

  public static getUpdatedItems<T>(
    array: T[],
    selector: (item: T) => boolean,
    update: (item: T) => void): T[] {

    return array.map(item => {
      if (selector(item)) {
        const updatedItem = { ...item };
        update(updatedItem);
        return updatedItem;
      }
      else {
        return item;
      }
    });
  }

  public static containSameData<T>(arrayA: T[], arrayB: T[], predicateCompare: (a: T, b: T) => boolean) {

    if (arrayA.length !== arrayB.length) {
      return false;
    }

    for (let i = 0; i < arrayA.length; i++) {
      if (!predicateCompare(arrayA[i], arrayB[i])) {
        return false;
      }
    }

    return true;
  }

  public static nextIndex<T>(index: number, data: T[]): number {
    return (index + 1) % data.length;
  }

  public static last<T>(array: T[], count: number): T[] {
    return array.slice(array.length - count);
  }

  public static first<T>(array: T[], count: number): T[] {
    return array.slice(0, count);
  }

  public static insertAfterOrAtTheEnd<T>(array: T[], itemToInsert: T, predicate: (item: T) => boolean): T[] {
    const index = array.findIndex(predicate);
    if (index === -1) {
      return [...array, itemToInsert];
    }

    const newArray = [...array];
    newArray.splice(index + 1, 0, itemToInsert);
    return newArray;
  }
}