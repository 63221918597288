import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaginationUtil } from "../../../../common/util/pagination/paginationUtil";
import { BlogPost } from "../../../../common-app/blogs/models/blogPost";
import { useQueryParam } from "../../../hooks/routes/useQueryParam";
import { BlogRouteParams } from "./blogRouteParams";
import { PaginationControlsComponent } from "../../pagination/pagination";
import { DateUtil } from "../../../../common/util/date/dateUtil";
import { UrlBuilderBlogPost } from "./util/UrlBuilderBlogPost";
import { DynamicPageBlogProvider } from "./provider/dynamicPageBlogProvider";

import './dynamicPageBlogPostsList.css';

interface DynamicPageBlogPostsListProps {
  dataProvider: DynamicPageBlogProvider
  siteId: string;
  pageId: string;
}

export function DynamicPageBlogPostsListComponent({
  dataProvider,
  siteId,
  pageId
}: DynamicPageBlogPostsListProps): JSX.Element {

  const currentPagePath = window.location.pathname;

  const pageIndex = Number(useQueryParam(BlogRouteParams.page, '1'));
  const BlogPerPageCount = 5;

  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [blogPostsCount, setBlogPostsCount] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {

    const getBlogPosts = async () => {
      // Get the blog posts from the backend
      const blogPosts = await dataProvider.getBlogPosts(pageId, BlogPerPageCount, pageIndex);
      setBlogPosts(blogPosts);
    }

    const getBlogPostsCount = async () => {
      // Get the blog posts from the backend
      const blogPostsCount = await dataProvider.getBlogPostCount(pageId);
      setBlogPostsCount(blogPostsCount);
    }

    getBlogPosts();
    getBlogPostsCount();

  }, [siteId, pageId, pageIndex]);

  return (
    <div className="dynamic-page-blog-posts-list-component">

      {
        blogPosts.map((post, index) => {
          return (
            <Link
              to={UrlBuilderBlogPost.buildPostLink(currentPagePath, post)}
              key={index}
            >
              <div key={index} className="blog-post">
                <div>
                  <div className="title">
                    {post.title}
                  </div>
                  <div className="subtitle">
                    {post.subTitle}
                  </div>
                  <div className="date">
                    {DateUtil.formatDateAsHumanReadable(post.creationDate)}
                  </div>
                </div>
              </div>
            </Link>
          )
        })
      }

      <PaginationControlsComponent
        pageCount={PaginationUtil.getRequiredPageCount(blogPostsCount, BlogPerPageCount)}
        currentPage={pageIndex}
        onPageChange={function (page: number): void {
          navigate(`${currentPagePath}?${BlogRouteParams.page}=${page}`);
        }}
        buttonsToShowCount={10}
        showFirstAndLast={true}
        showPreviousAndNext={true}
        hideOnSinglePage={true}
      ></PaginationControlsComponent>
    </div>
  )
}