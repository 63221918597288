import './verticalList.css';

interface VerticalListProps {
  children: React.ReactNode;
}

export function VerticalListComponent({ children }: VerticalListProps): JSX.Element {

  return (
    <div className="vertical-list-component">
      {children}
    </div>
  );
}