import Form from 'react-bootstrap/Form';

interface FormTextAreaProps {
    lines: number;
    readonly?: boolean;
    value: string | undefined;
    onChange: (value: string) => void;
}

export function FormTextAreaComponent({
    lines,
    value,
    readonly = false,
    onChange
}: FormTextAreaProps): JSX.Element {

    return (
        <Form.Control
            as="textarea"
            rows={lines}
            readOnly={readonly}
            value={value}
            onChange={(e) => {
                onChange(e.target.value);
            }}
        />
    );
}
