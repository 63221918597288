import { ForwardRefExoticComponent, RefAttributes } from "react";
import { CustomElement, CustomElementTypesEnum } from "../types";
import { ElementCreationUrlComponent } from "./elementCreation/url/elementCreationUrl";
import { ElementCreationNoneComponent } from "./elementCreation/none/elementCreationNone";
import { ElementCreationYoutubeShortComponent } from "./elementCreation/youtubeShort/elementCreationYoutubeShort";
import { ElementCreationYoutubeVideoComponent } from "./elementCreation/youtubeVideo/elementCreationYoutubeVideo";
import { ElementEditImageUserComponent } from "./elementEdit/imageUser/elementEditImageUser";
import { ElementEditImageUserGalleryComponent } from "./elementEdit/imageUserGallery/elementEditImageUserGallery";
import { ElementEditNoneComponent } from "./elementEdit/none/elementEditNone";
import { ElementEditUrlComponent } from "./elementEdit/url/elementEditUrl";
import { ElementEditYoutubeShortComponent } from "./elementEdit/youtubeShort/elementEditYoutubeShort";
import { ElementEditYoutubeVideoComponent } from "./elementEdit/youtubeVideo/elementEditYoutubeVideo";
import { ElementSettingsCreationProps } from "./elementSettingsCreationProps";
import { ElementSettingsEditProps } from "./elementSettingsEditProps";
import { ElementCreationUserQueryComponent } from "./elementCreation/userQuery/elementCreationUserQuery";
import { ElementEditUserQueryComponent } from "./elementEdit/userQuery/elementEditUserQuery";

export interface ElementCreationRef {
  createElement: () => CustomElement | undefined;
}

export interface ElementEditRef {
  updateElement: (element: CustomElement) => CustomElement | undefined;
}

export class ElementSettingsConfig {

  public static getElementCreationComponent(elementType: CustomElementTypesEnum):
    ForwardRefExoticComponent<ElementSettingsCreationProps & RefAttributes<ElementCreationRef>> {

    switch (elementType) {
      case CustomElementTypesEnum.Url:
        return ElementCreationUrlComponent;
      case CustomElementTypesEnum.YoutubeVideo:
        return ElementCreationYoutubeVideoComponent;
      case CustomElementTypesEnum.YoutubeShort:
        return ElementCreationYoutubeShortComponent;
      case CustomElementTypesEnum.UserQuery:
        return ElementCreationUserQueryComponent;
      default:
        return ElementCreationNoneComponent;
    }
  }

  public static getElementEditComponent(elementType: CustomElementTypesEnum):
    ForwardRefExoticComponent<ElementSettingsEditProps & RefAttributes<ElementEditRef>> {

    switch (elementType) {
      case CustomElementTypesEnum.ImageUser:
        return ElementEditImageUserComponent;
      case CustomElementTypesEnum.ImageUserGallery:
        return ElementEditImageUserGalleryComponent;
      case CustomElementTypesEnum.Url:
        return ElementEditUrlComponent;
      case CustomElementTypesEnum.YoutubeShort:
        return ElementEditYoutubeShortComponent;
      case CustomElementTypesEnum.YoutubeVideo:
        return ElementEditYoutubeVideoComponent;
      case CustomElementTypesEnum.UserQuery:
        return ElementEditUserQueryComponent;
      default:
        return ElementEditNoneComponent;
    }
  }
}