import { SitePageType } from "../../../common-app/sites/enums/sitePageType";

export type SitePageTypeInfo = {
  type: SitePageType;
  name: string;
  showInSelection: boolean;
}

export class SitePageTypesInfo {

  public static GetPageTypes(): SitePageTypeInfo[] {
    return [
      { type: SitePageType.Unknown, name: 'Desconhecido', showInSelection: false },
      { type: SitePageType.Static, name: 'Escrita Livre', showInSelection: true },
      { type: SitePageType.Contacts, name: 'Contactos', showInSelection: true },
      { type: SitePageType.Blog, name: 'Blog / Notícias', showInSelection: true },
    ];
  }

  public static GetPageTypeName(sitePageType: SitePageType): string {
    const pageTypeInfo = this.GetPageTypes().find(i => i.type === sitePageType);
    return pageTypeInfo ? pageTypeInfo.name : 'Desconhecido';
  }

}