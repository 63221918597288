import { Button } from "react-bootstrap";
import { HorizontalListComponent } from "../../../../horizontalList/horizontalList";
import { ElementConfigurationSnippetTextInputComponent } from "../elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput";

interface ElementConfigurationSnippetUrlProps {
  url: string;
  setUrl: (url: string) => void;
}

export function ElementConfigurationSnippetUrlComponent(
  { url, setUrl }: ElementConfigurationSnippetUrlProps
): JSX.Element {

  return <HorizontalListComponent>
    <ElementConfigurationSnippetTextInputComponent
      caption='Destino'
      placeholder='https://www.exemplo.com'
      value={url}
      onChange={
        (value) => setUrl(value)
      }
    />
    <Button
      size="sm"
      variant="outline-primary"
      disabled={url === ''}
      onClick={() => {
        window.open(url, '_blank');
      }}
    >
      <HorizontalListComponent>
        <i className="bi bi-box-arrow-up-right" />
        Testar
      </HorizontalListComponent>
    </Button>

  </HorizontalListComponent>
}