import { Alert } from "react-bootstrap";
import classNames from "classnames";

import './alertInline.css';

interface AlertInlineProps {
  //text: string;
  children: JSX.Element;
  variant: 'danger' | 'warning' | 'info' | 'success';
}

export function AlertInlineComponent({ children, variant }: AlertInlineProps): JSX.Element {

  const cn = classNames('alert-inline-component rounded', {
    'alert-inline-component-danger': variant === 'danger',
    'alert-inline-component-warning': variant === 'warning',
    'alert-inline-component-info': variant === 'info',
    'alert-inline-component-success': variant === 'success'
  });

  return <>
    <div className={cn}>
      {children}
    </div>
  </>
}
