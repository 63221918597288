import { Link } from 'react-router-dom';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { LogoComponent } from '../../../../common-frontend/components/logo/logo';
import { Spanner } from '../../../../common-frontend/components/micro/spanner/spanner';

import './dynamicSiteFooter.css';

export function DynamicSiteFooterComponent(): JSX.Element {
    return (
        <div className="dynamic-site-footer-component">
            <HorizontalListComponent>
                <Spanner />
                <Link to="/">
                    <HorizontalListComponent>
                        <div className="logo">
                            <LogoComponent />
                        </div>
                        <div
                            className="text">
                            Feito com Pixel Vaidoso - 1-Click Website
                        </div>
                    </HorizontalListComponent>
                </Link>
                <Spanner />
            </HorizontalListComponent>
        </div >
    );
}
