import { StringUtil } from "../../../common/util/string/stringUtil";
import { SectionTitleComponent } from "../../../common-frontend/components/sectionTitle/sectionTitle";
import { SimpleTextAccordeonComponent } from "../../../common-frontend/components/accordeon/simpleText/simpleTextAccordeon";
import { SimpleTextAccordeonItem } from "../../../common-frontend/components/accordeon/simpleText/simpleTextAccordeonItem";

import "./reasons.css"

const reasons: SimpleTextAccordeonItem[] = [
  {
    eventKey: "0",
    title: "Profissionalismo",
    body: StringUtil.joinSentences(
      [
        "A presença online é um factor determinante para a credibilidade da sua apresentação.",
        "Ter um site é uma forma de mostrar que o seu negócio é sólido e profissional."
      ]
    )
  },
  {
    eventKey: "1",
    title: "Controlo total do conteúdo",
    body: StringUtil.joinSentences(
      [
        "O seu próprio site permite-lhe ter controlo total do conteúdo que é apresentado aos seus clientes.",
        "Apenas você decide o que é mostrado e como é mostrado."
      ]
    )
  },
  {
    eventKey: "2",
    title: "Propriedade dos dados",
    body: StringUtil.joinSentences(
      [
        "Evite depender de plataformas de terceiros que podem mudar as regras a qualquer momento.",
        "Use as redes sociais para aumentar os seus clientes, mas não dependa delas porque inevitavelmente, elas irão ser substituidas por outras."
      ]
    )
  },
  {
    eventKey: "3",
    title: "Marca personalizada",
    body: StringUtil.joinSentences(
      [
        "Ter um site permite-lhe ter uma marca personalizada e distinta",
        "Não necessita de formatar ou ajustar a sua imagem às plataformas.",
        "Pode mostrar o que deseja, da forma como deseja."
      ]
    )
  },

  {
    eventKey: "4",
    title: "Sem comissões ou taxas",
    body: StringUtil.joinSentences(
      [
        "Com um website próprio você mantém todas as receitas geradas pelas vendas efectuadas.",
        "Mais lucro e a liberdade de definir os seus próprios termos de venda, sem repartir ganhos com as plataformas."
      ]
    )
  },

  {
    eventKey: "5",
    title: "Flexibilidade de funcionalidades",
    body: StringUtil.joinSentences(
      [
        "Pode adicionar funcionalidades específicas ao seu website.",
        "Vendas online, blogs, marcação de eventos, processos personalizados são apenas exemplos de como o seu website pode complementar o seu negócio."
      ]
    )
  },

  {
    eventKey: "6",
    title: "Resiliência e sustentabilidade",
    body: StringUtil.joinSentences(
      [
        "Plataformas sociais podem mudar drasticamente ao longo do tempo ou até desaparecer.",
        "Um website próprio é uma presença online que você pode manter e evoluir conforme necessário, independentemente das tendências nas redes sociais.",
        "Use-as para promover o seu serviço e angariar clientes, mas não coloque todos os ovos no mesmo cesto.",
        "O seu website será seu até ao dia em que quiser."
      ]
    )
  }
];

export function ReasonsComponent(): JSX.Element {

  return <div>
    <SectionTitleComponent>
      Por que devo ter um site?
    </SectionTitleComponent>

    <SimpleTextAccordeonComponent items={reasons} />
  </div>
}