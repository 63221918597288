import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ImageUploaderModalComponent } from '../../../../imageUploader/imageUploaderModal';
import {
  GetImagesListFunction,
  UploadImageFunction
} from '../../../../imageUploader/imageBrowser';

import './elementConfigurationSnippetImageSelection.css';

interface ElementConfigurationSnippetImageSelectionProps {
  uploadImageFunction: UploadImageFunction,
  getImagesListFunction: GetImagesListFunction,
  onImageSelected: (image: string) => void
}

export function ElementConfigurationSnippetImageSelectionComponent({
  uploadImageFunction,
  getImagesListFunction,
  onImageSelected,
}: ElementConfigurationSnippetImageSelectionProps): JSX.Element {

  const [showDialog, setShowDialog] = useState(false);

  const handleImageSelect = (images: string[]) => {

    // ** Temporary code **
    if (images.length < 1) {
      return;
    }

    const image = images[0];

    onImageSelected(image);
  }

  return (
    <div className="element-configuration-snippet-image-selection-component">
      <Button
        onClick={() => {
          setShowDialog(true);
        }}
      >
        Escolher imagem
      </Button>

      <ImageUploaderModalComponent
        imagesSelected={[]}
        uploadImageFunction={uploadImageFunction}
        getImagesListFunction={getImagesListFunction}
        getImagesInfoFunction={() => { return Promise.resolve([]) }}
        showDialog={showDialog}
        allowMultipleSelection={false}
        onClose={() => {
          setShowDialog(false)
        }}
        onConfirm={(images) => {
          handleImageSelect(images);
          setShowDialog(false)
        }}
      />
    </div>
  );
}
