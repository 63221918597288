import { useEffect, useState } from "react";
import { CustomElementImageUserGallery } from "../../types";
import { ServiceApiUserFiles } from "../../../../../services/api/serviceApiUserFiles";
import { ImageGalleryComponent } from "../../../imageGallery/imageGallery";
import { RenderComponentProps } from "../renderComponentProps";
import { ImageGalleryItem } from "../../../imageGallery/imageGalleryItem";

import './renderImageUserGallery.css';
import { useRichTextEditorDataContext } from "../../contexts/dataContext/hooks/useRichTextEditorDataContext";

export function RenderImageUserGalleryComponent({
  element,
  attributes,
  children }: RenderComponentProps): JSX.Element {

  const elementImageUserGallery = element as CustomElementImageUserGallery;

  const [galleryImages, setGalleryImages] = useState<ImageGalleryItem[]>();

  const dataContext = useRichTextEditorDataContext();

  useEffect(() => {

    const fetchImageData = async () => {

      const imageIds = elementImageUserGallery.images.map((image) => image.imageId);
      const userFileImagesInfo = await ServiceApiUserFiles.getUserImagesInfo(
        dataContext.siteId,
        imageIds
      );

      const galleryImages = elementImageUserGallery.images.map((image) => {

        const imageInfo = userFileImagesInfo.find((userFileImageInfo) => userFileImageInfo.id === image.imageId);
        if (imageInfo === undefined) {
          return null;
        }

        return {
          id: image.imageId,
          url: dataContext.backendServerAddress + imageInfo.publicUrl,
          urlthumbnail: dataContext.backendServerAddress + imageInfo.publicUrlThumbnail,
          altText: imageInfo.originalFileName,
          caption: image.caption
        }
      }).filter((imageInfo) => imageInfo !== null) as ImageGalleryItem[];

      setGalleryImages(galleryImages);
    }

    fetchImageData();

  }, [element]);

  return (
    <div className="editor-user-image-gallery-node-parent">
      <div
        {...attributes}
        contentEditable={false}
        className="editor-user-image-gallery-node">

        {galleryImages &&

          <div
            className="gallery-holder"
          >
            <ImageGalleryComponent
              images={galleryImages}
            />

            <div
              className="gallery-caption">
              {elementImageUserGallery.caption}
            </div>
          </div>
        }
        {children}
      </div>
    </div>
  )
}
