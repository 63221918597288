import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { VerticalListComponent } from "../../../../verticalList/verticalList";
import { CustomElement, CustomElementYoutubeVideo } from "../../../types";
import { ElementSettingsEditProps } from "../../elementSettingsEditProps";
import { ElementConfigurationSnippetTextInputComponent } from "../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput";
import { ElementConfigurationSnippetYoutubeContentComponent } from "../../snippets/elementConfigurationSnippetYoutubeContent/elementConfigurationSnippetYoutubeContent";
import { ElementConfigurationSnippetYoutubeVideoPreviewComponent } from "../../snippets/elementConfigurationSnippetYoutubeVideoPreview/elementConfigurationSnippetYoutubeVideoPreview";
import { ElementEditRef } from "../../elementSettingsConfig";

export const ElementEditYoutubeVideoComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({
    element,
    elementCanBeUpdated
  }, ref): JSX.Element => {

    const elementYoutubeVideo = element as CustomElementYoutubeVideo;

    const [videoId, setVideoId] = useState<string>(elementYoutubeVideo.videoId);
    const [caption, setCaption] = useState<string>(elementYoutubeVideo.caption);

    useImperativeHandle(ref, () => ({
      updateElement: (element: CustomElement) => {
        const updatedElement = element as CustomElementYoutubeVideo;
        updatedElement.videoId = videoId;
        updatedElement.caption = caption;
        return updatedElement;
      },
    }));

    useEffect(() => {
      elementCanBeUpdated(videoId.length > 0);
    }, [videoId]);

    return (
      <div className="element-edit-youtube-Video-component">
        <VerticalListComponent>

          <ElementConfigurationSnippetYoutubeContentComponent
            videoId={videoId}
            setVideoId={setVideoId}
          />

          <ElementConfigurationSnippetYoutubeVideoPreviewComponent
            videoId={videoId}
          />

          <ElementConfigurationSnippetTextInputComponent
            caption='Legenda'
            placeholder=''
            value={caption}
            onChange={setCaption}
          />
        </VerticalListComponent>
      </div>
    );
  }
);