import { useState } from "react";
import { Button } from "react-bootstrap";
import { ImageUploaderModalComponent } from "../../../../imageUploader/imageUploaderModal";
import { RichTextEditorContentManager } from "../../../richTextEditorContentManager";

interface ButtonImageProps {
  contentManager: RichTextEditorContentManager;
  handleImageInsert: (images: string[]) => void;
}

export function ButtonImage({
  contentManager,
  handleImageInsert
}: ButtonImageProps): JSX.Element {

  const [showDialog, setShowDialog] = useState(false);

  return <>
    <Button
      onClick={() => { setShowDialog(true); }}
    >
      Inserir Imagem
    </Button >

    <ImageUploaderModalComponent
      imagesSelected={[]}
      uploadImageFunction={contentManager.uploadImageFunction}
      getImagesListFunction={contentManager.getImagesListFunction}
      getImagesInfoFunction={contentManager.getImagesInfoFunction}
      allowMultipleSelection={true}
      showDialog={showDialog}
      onClose={() => {
        setShowDialog(false)
      }}
      onConfirm={(images) => {
        handleImageInsert(images);
        setShowDialog(false)
      }}
    />
  </>
}