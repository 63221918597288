import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { CustomElement, CustomElementUserQuery } from '../../../types';
import { ElementSettingsEditProps } from '../../elementSettingsEditProps';
import { ElementConfigurationSnippetTextInputComponent } from '../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';
import { ElementEditRef } from '../../elementSettingsConfig';

export const ElementEditUserQueryComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({
    element,
    elementCanBeUpdated
  }, ref): JSX.Element => {

    const elementUserQuery = element as CustomElementUserQuery;

    const [name, setName] = useState<string>(elementUserQuery.name);

    useEffect(() => {
      elementCanBeUpdated(name.length > 0);
    }, [name]);

    useImperativeHandle(ref, () => ({
      updateElement: (element: CustomElement) => {
        const updatedElement = element as CustomElementUserQuery;
        updatedElement.name = name;
        return updatedElement;
      },
    }));

    return (
      <div className="element-edit-user-query-component">
        <VerticalListComponent>
          <ElementConfigurationSnippetTextInputComponent
            caption='Nome'
            value={name}
            onChange={setName}
          />
        </VerticalListComponent>
      </div>
    );
  }
);