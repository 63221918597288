import { Link, useNavigate } from 'react-router-dom';
import { Container, Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import { LogoComponent } from '../../common-frontend/components/logo/logo';
import { Spanner } from '../../common-frontend/components/micro/spanner/spanner';
import { useUserSessionContext } from '../contexts/session/hooks/useUserSessionContext';
import { ServiceApiSystem } from '../../services/api/serviceApiSystem';
import { LocalStorageSession } from '../../library/localStorage/session/session';
import { VerticalListComponent } from '../../common-frontend/components/verticalList/verticalList';
import { VerticalWhiteSpaceComponent } from '../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace';

import '../../common-frontend/components/common-styles.css';
import './header.css';

export function HeaderComponent(): JSX.Element {

  const navigate = useNavigate();
  const userSessionContext = useUserSessionContext();

  const [showNavigationPanel, setShowNavigationPanel] = useState<boolean>(false);

  const onShowNavigationPanel = () => {
    setShowNavigationPanel(!showNavigationPanel);
  }

  const handleLogout = () => {

    const sessionId = userSessionContext.userSessionId;

    ServiceApiSystem.logout(sessionId).then(() => {
      LocalStorageSession.deleteSessionId();
      LocalStorageSession.deleteUserId();

      userSessionContext.clearSessionInformation();

      navigate('/');
    });
  }

  const onNavigationFromSidePanel = () => {
    setShowNavigationPanel(false);
  }

  return (
    <>

      <Container>
        <div className='component-header flex-h'>

          <div
            className="side-menu-toogle"
            onClick={onShowNavigationPanel}
          >
            <i className="bi bi-list" />
          </div>
          {/* <Spanner /> */}

          <Link to={'/'} >
            <div className='logo-holder'>
              <LogoComponent />
            </div>
          </Link>

          <div className="header-items flex-h">

            <Link to={'/services'}>
              <div className='item'>
                Serviços
              </div>
            </Link>

            <Link to={'/1-click'}>
              <div className='item'>
                Sites 1-Click
              </div>
            </Link>

            <Link to={'/contacts'}>
              <div className='item'>
                Contactos
              </div>
            </Link>

            <Spanner />
            {
              userSessionContext.userId &&
              <>
                <Link to={'/user-sites'}>
                  <div className='item'>
                    Sites
                  </div>
                </Link>

                <div className='item'
                  onClick={() => handleLogout()}>
                  Sair
                </div>
              </>
            }

            {
              !userSessionContext.userId &&
              <Link to={'/signin'} >
                <div className='item'>
                  Iniciar sessão
                </div>
              </Link>
            }

          </div>

        </div>
      </Container>

      <Offcanvas show={showNavigationPanel} onHide={() => { setShowNavigationPanel(false) }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link className="no-decor" to={'/'} >
              <div
                style={{ width: '50px' }}
                onClick={onNavigationFromSidePanel}
              >
                <LogoComponent />
              </div>
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <div className="side-panel-navigation" >
            <VerticalListComponent>

              <Link to={'/'} >
                <div
                  className='side-panel-item'
                  onClick={onNavigationFromSidePanel}
                >
                  Página inicial
                </div>
              </Link>

              <VerticalWhiteSpaceComponent ammount={1} />

              <Link to={'/services'}>
                <div
                  className='side-panel-item'
                  onClick={onNavigationFromSidePanel}
                >
                  Serviços
                </div>
              </Link>

              <Link to={'/1-click'}>
                <div
                  className='side-panel-item'
                  onClick={onNavigationFromSidePanel}
                >
                  Sites 1-Click
                </div>
              </Link>

              <Link to={'/contacts'}>
                <div
                  className='side-panel-item'
                  onClick={onNavigationFromSidePanel}
                >
                  Contactos
                </div>
              </Link>

              <VerticalWhiteSpaceComponent ammount={1} />
              {
                userSessionContext.userId &&
                <>
                  <Link to={'/user-sites'}>
                    <div
                      className='side-panel-item'
                      onClick={onNavigationFromSidePanel}
                    >
                      Sites
                    </div>
                  </Link>

                  <Link to={'/'} >
                    <div
                      className='side-panel-item'
                      onClick={() => {
                        onNavigationFromSidePanel();
                        handleLogout()
                      }}>
                      Terminar sessão
                    </div>
                  </Link>
                </>
              }

              {
                !userSessionContext.userId &&
                <>

                  <Link to={'/signin'} >
                    <div
                      className='side-panel-item'
                      onClick={onNavigationFromSidePanel}
                    >
                      Iniciar sessão
                    </div>
                  </Link>

                  <Link to={'/signup'} >
                    <div
                      className='side-panel-item'
                      onClick={onNavigationFromSidePanel}
                    >
                      Criar conta
                    </div>
                  </Link>
                </>
              }
            </VerticalListComponent>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}