import { useCallback, useEffect, useState } from "react"
import classNames from "classnames";
import { UserFileImage } from "../../../common-app/userFiles/models/userFileImage";
import { ArrayUtil } from "../../../common/util/array/arrayUtil";
import { GuidUtil } from "../../../common/util/guid/guid";
import { ImageCaptionComponent } from "../imageCaption/imageCaption";

import './imageBrowser.css';

export type UploadImageFunction = (file: File, name: string, id: string) => Promise<boolean>;
export type GetImagesListFunction = () => Promise<UserFileImage[]>;
export type GetImagesInfoFunction = (imageIds: string[]) => Promise<UserFileImage[]>

enum UploadStatus {
  NotUploaded = "Not uploaded",
  Uploading = "Uploading",
  UploadSuccessful = "Upload successful",
  UploadFailed = "Upload failed"
}

class UploadFileInformation {
  file: File | undefined; // The file to upload
  uploadStatus: UploadStatus = UploadStatus.NotUploaded;
  fileId: string = ""; // The file id
  previewUrl: string = ""; // The preview url
}

interface ImageBrowserComponentProps {
  uploadImageFunction: UploadImageFunction;
  getImagesListFunction: GetImagesListFunction;
  selectedFiles: string[];
  setSelectedFiles: (files: string[]) => void;
}

export function ImageBrowserComponent({
  uploadImageFunction,
  getImagesListFunction,
  selectedFiles,
  setSelectedFiles
}: ImageBrowserComponentProps): JSX.Element {

  // Files currently in the server
  const [serverFiles, setServerFiles] = useState<UserFileImage[]>([]);

  // Files upload by the user
  const [uploadFiles, setUploadFiles] = useState<UploadFileInformation[]>([]);

  const addFileToSelection = (fileId: string) => {
    setSelectedFiles([...selectedFiles, fileId]);
  }

  const removeFileFromSelection = (fileId: string) => {
    setSelectedFiles(selectedFiles.filter((id) => id !== fileId));
  };

  const toggleFileSelection = (fileId: string) => {
    if (selectedFiles.includes(fileId)) {
      removeFileFromSelection(fileId);
    } else {
      addFileToSelection(fileId);
    }
  }

  const updateActiveFileStatus = (fileId: string, status: UploadStatus): void => {
    setUploadFiles((current) => {
      return ArrayUtil.getUpdatedItems(
        current,
        (item) => item.fileId === fileId,
        (item) => item.uploadStatus = status);
    });
  }

  const uploadFile = async (file: UploadFileInformation) => {

    if (file.file === undefined) {
      updateActiveFileStatus(file.fileId, UploadStatus.UploadFailed);
      return;
    }

    updateActiveFileStatus(file.fileId, UploadStatus.Uploading);

    try {

      const result = await uploadImageFunction(file.file, file.file.name, file.fileId);
      if (result) {
        updateActiveFileStatus(file.fileId, UploadStatus.UploadSuccessful);
      } else {
        updateActiveFileStatus(file.fileId, UploadStatus.UploadFailed);
      }
    } catch (error) {
      updateActiveFileStatus(file.fileId, UploadStatus.UploadFailed);
    }
  };

  const handleClickOnUploadedFile = (file: UploadFileInformation) => {
    if (file.uploadStatus === UploadStatus.UploadSuccessful) {
      toggleFileSelection(file.fileId);
    }
  }

  const handleClickOnServerFile = (file: UserFileImage) => {
    toggleFileSelection(file.id);
  }

  // Handling the drag and drop of images
  const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {

    event.preventDefault();

    // Get the dropped files
    const droppedFiles = Array.from(event.dataTransfer.files);

    // Create a new UploadFileInformation object for each file
    const newFiles = droppedFiles.map((file) => {
      const uploadFileInformation = new UploadFileInformation();
      uploadFileInformation.file = file;
      uploadFileInformation.fileId = GuidUtil.GenerateNewGuid();
      uploadFileInformation.uploadStatus = UploadStatus.NotUploaded;
      uploadFileInformation.previewUrl = URL.createObjectURL(file)
      return uploadFileInformation;
    });

    // Add the new files to the existing files
    setUploadFiles(() => [...newFiles, ...uploadFiles]);

  }, [uploadFiles]);

  useEffect(() => {

    // Load the files in the server
    const loadFilesFromServer = async () => {
      const files = await getImagesListFunction();
      setServerFiles(files);
    };

    loadFilesFromServer();
  }, []);

  useEffect(() => {
    uploadFiles.forEach((file) => {
      if (file.uploadStatus === UploadStatus.NotUploaded) {
        uploadFile(file);
      }
    });
  }, [uploadFiles]);

  return (
    <div className="image-browser-component"
      onDrop={onDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <div
        className='drop-area'
      >
        <p>Arraste novas imagens para aqui</p>
      </div>

      <div
        className='images-container'
      >
        {/* Images being uploaded */}
        {uploadFiles.map((file, index) => (
          <div
            className={classNames("file-item",
              { 'uploading': file.uploadStatus === UploadStatus.Uploading },
              { 'uploaded': file.uploadStatus === UploadStatus.UploadSuccessful },
              { 'upload-error': file.uploadStatus === UploadStatus.UploadFailed },
              { 'selected': selectedFiles.includes(file.fileId) }
            )}
            key={index}
            onClick={() => handleClickOnUploadedFile(file)}
            onDrag={(e) => { e.preventDefault(); handleClickOnUploadedFile(file) }}
          >
            <img
              draggable={false}
              src={file.previewUrl}
              alt={file.file?.name}
              className={classNames('preview',
                { 'grayscale': file.uploadStatus !== UploadStatus.UploadSuccessful })}
            />

            {file.uploadStatus === UploadStatus.UploadSuccessful && file.file && (
              <ImageCaptionComponent
                caption={file.file?.name}
              />
            )}

            {file.uploadStatus === UploadStatus.Uploading && (
              <div
                className="hourglass"
              >
                <i className="jiggle bi bi-hourglass-split"></i>
              </div>
            )}
          </div>
        ))}

        {/* Server images */}
        {serverFiles.map((file, index) => (
          <div
            className={classNames("file-item",
              { 'selected': selectedFiles.includes(file.id) }
            )}
            key={index}
            onClick={() => handleClickOnServerFile(file)}
          >
            <img
              draggable={false}
              src={file.publicUrlThumbnail}
              alt={file.originalFileName}
              className={classNames('preview')}
            />

            <ImageCaptionComponent
              caption={file.originalFileName}
            />
          </div>
        ))}
      </div>
    </div>
  )
}