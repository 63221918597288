import { useEffect, useState } from "react";
import { SitePageFeature } from "../../../../common-app/sites/enums/sitePageFeature";
import { ServiceApiSites } from "../../../../services/api/serviceApiSites";
import { VerticalListComponent } from "../../../../common-frontend/components/verticalList/verticalList";
import { PageFeatureComponent } from "./pageFeature";

interface PageFeaturesProps {
  siteId: string;
  pageId: string;
  pageFeatures: SitePageFeature[];
}

export function PageFeaturesComponent({
  siteId,
  pageId,
  pageFeatures
}: PageFeaturesProps): JSX.Element {

  return <div className="page-features-component">
    <VerticalListComponent>
      {pageFeatures.map((feature, index) => {
        return <PageFeatureComponent
          key={index}
          siteId={siteId}
          pageId={pageId}
          feature={feature}
        />
      })}
    </VerticalListComponent>
  </div>
}